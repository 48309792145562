import axios from 'axios'
const token = localStorage.getItem("remember_me") ? JSON.parse(localStorage.getItem("remember_me")).access_token : sessionStorage.getItem("remember_me") ? JSON.parse(sessionStorage.getItem("remember_me")).access_token : '';
const apiURL = process.env.REACT_APP_BASE_API;
const apiClient = axios.create({
    baseURL: apiURL,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        Authorization: "Bearer " + token
    }
})

class ServiceApi {
    authUsers(data) {
        return axios.post(`${apiURL}/login`, data);
    };

    //KSI
    getListNotifikasi(data = '') {
        return apiClient.get(`/notification/list?${data}`);
    }

    getCountNotification() {
        return apiClient.get(`/notification/number`);
    }

    readNotification(data) {
        return apiClient.post(`/notification/read`, data);
    }

    getDetailProfile(data) {
        return apiClient.get(`/profile/detail`);
    }

    updateProfile(data) {
        return apiClient.post(`/profile/update`, data)
    }

    getListApproval(data = {}) {
        return apiClient.post(`/user/approval-list`, data);
    }

    addApproval(data) {
        return apiClient.post(`/user/approve`, data)
    }

    rejectApproval(data) {
        return apiClient.post(`/user/reject`, data)
    }

    updateApproval(data) {
        return apiClient.post(`/user/update`, data)
    }

    detailUser(data = {}) {
        return apiClient.post(`/user/detail`, data)
    }

    deleteUser(data) {
        return apiClient.post(`/user/delete`, data)
    }

    getListAkunAktif(data = {}) {
        return apiClient.post(`/user/active-list`, data);
    }

    getListAkunTidakAktif(data = {}) {
        return apiClient.post(`/user/inactive-list`, data);
    }

    getListRefKsi(data = {}) {
        return axios.post(`${process.env.REACT_APP_BASE_API_USER}/get-master`, data)
    }

    getInterest(data) {
        return apiClient.post(`/interest/list`, data);
    }

    addInterest(data) {
        return apiClient.post(`/interest/create`, data)
    }

    updateInterest(data) {
        return apiClient.post(`/interest/update`, data)
    }

    deleteInterest(data) {
        return apiClient.post(`/interest/delete`, data)
    }

    getEvents(data) {
        return apiClient.post(`/event/list`, data);
    }

    detailEvents(data = {}) {
        return apiClient.post(`/event/detail`, data)
    }

    deleteEvent(data) {
        return apiClient.post(`/event/delete`, data)
    }

    addEvents(data) {
        return apiClient.post(`/event/create`, data)
    }

    getSelect(data) {
        return apiClient.post(`/data/select-option/default`, data);
    }

    updateEvents(data) {
        return apiClient.post(`/event/update`, data)
    }

    //DASHBOARD
    getDashboardData(data = '') {
        return apiClient.get(`/dashboard/data` + data);
    }

    getDashboardJumlahPostingan(data = {}) {
        return apiClient.post(`/dashboard/jumlah-post`, data);
    }

    //KELAS
    getKelas(data = {}) {
        return apiClient.post(`/class/list`, data);
    }

    addKelas(data) {
        return apiClient.post(`/class/create`, data)
    }

    updateKelas(data) {
        return apiClient.post(`/class/update`, data)
    }

    deleteKelas(data) {
        return apiClient.post(`/class/delete`, data)
    }

    //TAHUN BELAJAR
    getTahunBelajar(data = {}) {
        return apiClient.post(`/study-year/list`, data);
    }

    addTahunBelajar(data) {
        return apiClient.post(`/study-year/create`, data)
    }

    updateTahunBelajar(data) {
        return apiClient.post(`/study-year/update`, data)
    }

    deleteTahunBelajar(data) {
        return apiClient.post(`/study-year/delete`, data)
    }

    //KATEGORI
    getKategori(data = {}) {
        return apiClient.post(`/category/list`, data)
    }

    addKategori(data = {}) {
        return apiClient.post(`/category/create`, data)
    }

    updateKategori(data = {}) {
        return apiClient.post(`/category/update`, data)
    }

    deleteKategori(data) {
        return apiClient.post(`/category/delete`, data)
    }

    //POSTINGAN USER
    getPostinganUser(data = {}) {
        return apiClient.post(`/post-user/list`, data)
    }

    getDetailPostinganUser(data = {}) {
        return apiClient.post(`/post-user/detail`, data)
    }

    deletePostinganUser(data) {
        return apiClient.post(`/post-user/delete`, data)
    }

    //POSTINGAN ADMIN
    getPostinganAdmin(data = {}) {
        return apiClient.post(`/post-admin/list`, data)
    }

    getDetailPostinganAdmin(data = {}) {
        return apiClient.post(`/post-admin/detail`, data)
    }

    addPostinganAdmin(data = {}) {
        return apiClient.post(`/post-admin/create`, data)
    }

    updatePostinganAdmin(data = {}) {
        return apiClient.post(`/post-admin/update`, data)
    }

    deletePostinganAdmin(data) {
        return apiClient.post(`/post-admin/delete`, data)
    }

    getManajemenUser(param) {
        return apiClient.get(`/master/user/getData?${param}`)
    }

    addManajemenUser(data) {
        return apiClient.post(`/master/user/create`, data)
    }

    editManajemenUser(data) {
        return apiClient.post(`/master/user/update`, data)
    }

    deleteManajemenUser(data) {
        return apiClient.post(`/master/user/delete`, data)
    }
}
export default ServiceApi;