import React, { useEffect } from "react";
import "firebase/messaging";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useState } from "react";

function PushNotificationLayout({ children }) {
  const router = useHistory();

  const [dataMessage, setDataMessage] = useState(null);

  useEffect(() => {
    //setToken();

    // Event listener that listens for the push notification event in the background
    console.log("checking serviceWorker...");
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        console.log("event.data?.firebaseMessaging?.payload", event.data);
        setDataMessage(event.data);
      });
    }else{
      console.log("serviceWorker not in navigator");
    }
    return () => getMessage(dataMessage);
  }, []);

  // Handles the click function on the toast showing push notification
  const handleClickPushNotification = (url) => {
    if(url){
      router.push(url);
    }
  };

  // Get the push notification message and triggers a toast to display it
  function getMessage(message) {
    try {
      toast(
        <div onClick={() => handleClickPushNotification(message?.data?.url)}>
          <h5>{message?.notification?.title}</h5>
          <h6>{message?.notification?.body}</h6>
        </div>,
        {
          closeOnClick: false,
        }
      );
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <ToastContainer
        progressStyle={{ background: "#F3BA68" }}
        limit={5} style={{
        marginTop: 60,
      }}/>
      {children}
    </>
  );
}

export default PushNotificationLayout;