import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import L from 'leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faClock, faPlus, faSearchLocation } from '@fortawesome/free-solid-svg-icons'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import axios from 'axios';
import Swal from 'sweetalert2'
import * as AiIcons from 'react-icons/ai';
import * as BsIcons from 'react-icons/bs';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import ServiceApi from '../../api/MyApi';

const TambahInterest = () => {
    const history = useHistory();

    const [checkedMetode, setCheckedMetode] = useState(1);
    useEffect(() => {

    }, [])

    const submitData = async (e) => {
        e.preventDefault();

        const data = {
            'year': e.target.elements.tahun_belajar.value
        }

        new ServiceApi().addTahunBelajar(data)
            .then(response => {
                Swal.fire({
                    title: 'Sukses!',
                    html: '<i>' + response.data.message + '</i>',
                    icon: 'success',
                    confirmButtonColor: '#0058a8',
                }).then(function () {
                    history.replace('/tahun-belajar')
                })
            }).catch(err => {
                Swal.fire({
                    title: 'Gagal!',
                    html: '<i>' + err.response.data.data.year ? err.response.data.data.year : '' + '</i>',
                    icon: 'error',
                    confirmButtonColor: '#0058a8',
                })
            });
    }
    const setCheckMetode = (e) => {
        setCheckedMetode(e)
    }

    return (
        <div className='main-animation'>
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div>
                    <Link className="content-link" to={{ pathname: `/tahun-belajar` }}><h3 className="content-title"><FontAwesomeIcon icon={faArrowLeft} size="sm" />&nbsp; Tambah Tahun Belajar</h3></Link>
                </div>
            </div>

            <Form onSubmit={submitData}>
                <Card className="card-main-content">
                    <Card.Body>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="12">
                                Nama Tahun Belajar
                            </Form.Label>
                            <Col sm="12">
                                <Form.Control className="custom-form-control" type="text" name="tahun_belajar" placeholder="Masukkan nama tahun belajar" autoComplete="off" />
                            </Col>
                            <Col sm="12">
                                <Button className="content-button-submit" variant="primary" type="submit">Tambah</Button>
                            </Col>
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Form>
        </div>
    );
};

export default TambahInterest;
