import _ from "lodash";
import Medal1 from "../img/medal-1.png";
import Medal2 from "../img/medal-2.png";
import Medal3 from "../img/medal-3.png";

export const longText = (text) => {
    return _.isString(text) ? text.length > 40 ? text.substring(0, 33) + "..." : text : '-';
}

export const capitalize = (str) => {
    const lower = str.toLowerCase();
    return str.charAt(0).toUpperCase() + lower.slice(1);
}

export const listJenisDokumen = [
    { value: null, label: 'Tidak Mencantumkan Dokumen' },
    { value: 1, label: 'Surat Tugas' },
    { value: 2, label: 'Brosur' },
    { value: 3, label: 'Undangan' },
    //{ value: 4, label: 'Daftar Hadir' },
    { value: 5, label: 'Daftar Hadir Peserta' }
]

export const longTextV2 = (text, lengthCustom = 0, limitCustom = 10) => {
    return _.isString(text) ? text.length > lengthCustom ? text.substring(0, lengthCustom) + "..." : text : '-';
}

export const formatedSelect = (data = [], keyId, keyLabel) => {
    return !_.isEmpty(data) ? data.map(x => (
        {
            "value": x[keyId],
            "label": x[keyLabel]
        }
    )) : [{ value: null, label: null }]
}

export const defineRank = (rank) => {
    if (rank == 1) {
        return <img
            alt="medal1"
            src={Medal1}
            width="30px"
            height="30px"
        />;
    } else if (rank == 2) {
        return <img
            alt="medal2"
            src={Medal2}
            width="30px"
            height="30px"
        />;
    } else if (rank == 3) {
        return <img
            alt="medal3"
            src={Medal3}
            width="30px"
            height="30px"
        />;
    } else {
        return <div
            style={{
                fontSize: "16px",
                marginRight: "5px",
                marginLeft: "5px",
                fontWeight: "bold",
            }}
        >{rank}</div>;
    }
}