import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import L from 'leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCalendar, faClock, faComment, faDownload, faEye, faMapMarkedAlt, faMapMarkerAlt, faPaperclip, faPaperPlane, faPlus, faSearchLocation, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { Button, Card, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import axios from 'axios';
import Swal from 'sweetalert2'
import * as AiIcons from 'react-icons/ai';
import * as BsIcons from 'react-icons/bs';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom'; import ServiceApi from '../../../api/MyApi';
import ReactSelect from 'react-select';
;

const DetailAkunAktif = () => {
    const history = useHistory();
    const location = useLocation();
    const myparam = location.state;
    const [detail, setDetail] = useState([]);
    const [tgl_lahir, setTglLahir] = useState('');
    const [email, setEmail] = useState('');
    const [nomor_id, setNomorID] = useState('');
    const [status, setStatus] = useState('');
    const [listTahun, setListTahun] = useState([]);
    const [tahun, setTahun] = useState('');
    const [listKelas, setListKelas] = useState([]);
    const [kelas, setKelas] = useState('');
    const [sertifikat, setSeritifikat] = useState([]);
    const [nomor, setNomor] = useState('');

    useEffect(() => {
        // if (typeof window !== 'undefined') {
        //     var queryParams = new URLSearchParams(window.location.search);
        //     var isRedirect = queryParams.get('username');
        // }

        new ServiceApi().detailUser({ username: myparam.detail.username, editable: 1 }).then(x => {
            setDetail(x.data.user);
            setTglLahir(x.data.user.tgl_lahir);
            setEmail(x.data.user.email);
            setNomorID(x.data.user.student_id);
            setStatus(x.data.user.status);

            const list_tahun = x.data.tahun.map(row => {
                return { value: row.id, label: row.year }
            })
            setListTahun(list_tahun)
            setTahun(x.data.user.tahun)

            const list_kelas = x.data.kelas.map(row => {
                return { value: row.id, label: row.class }
            })
            setListKelas(list_kelas)
            setKelas(x.data.user.kelas)

            setSeritifikat(x.data.user.certificate)
            setNomor(x.data.user.phone)
        }).catch((err) => {
        })
    }, [])

    const listStatus = [
        { value: 1, label: 'Aktif' },
        { value: 2, label: 'Alumni' }
    ]


    const selectedStatus = (e) => {
        setStatus(e.value)
    }

    const selectedTahun = (e) => {
        setTahun(e.value)
    }

    const selectedKelas = (e) => {
        setKelas(e.value)
    }

    const submitData = async (e) => {
        e.preventDefault();

        const data = {
            'key': detail.encrypted_id,
            'nomor_id': nomor_id,
            'email': email,
            'tanggal_lahir': tgl_lahir,
            'status': status,
            'tahun_terakhir': tahun,
            'kelas': kelas,
            'phone': e.target.elements.phone.value,
        }

        new ServiceApi().updateApproval(data)
            .then(response => {
                Swal.fire({
                    title: 'Sukses!',
                    html: '<i>' + response.data.message + '</i>',
                    icon: 'success',
                    confirmButtonColor: '#0058a8',
                }).then(function () {
                    history.push('/user/aktif')
                })
            }).catch(err => {
                Swal.fire({
                    title: 'Gagal!',
                    html: '<i>' + err.response.data.message + '</i>',
                    icon: 'error',
                    confirmButtonColor: '#0058a8',
                })
            });
    }

    if (!myparam) return <Redirect to="/user/aktif" />

    return (
        <div className='main-animation'>
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div>
                    <Link className="content-link" to={{ pathname: `/user/aktif` }}><h3 className="content-title"><FontAwesomeIcon icon={faArrowLeft} size="sm" />&nbsp; Detail User</h3></Link>
                </div>
            </div>

            <Form onSubmit={submitData}>
                <Card className="card-main-content">
                    <Card.Body>
                        <div className='head-table'>
                            <div id='size-table' className='size-table'>
                                <div>
                                    {detail.avatar == null ?
                                        <div style={{ width: '100px', height: '100px', backgroundColor: '#828282', borderRadius: '50%', marginRight: '30px' }}></div>
                                        :
                                        <img
                                            style={{ width: '100px', height: '100px', borderRadius: '50%', marginRight: '30px' }}
                                            src={process.env.REACT_APP_BASE_URL_STORAGE_AVATAR_USER + detail.avatar}
                                        />
                                    }
                                </div>
                                <div>
                                    <h3 style={{ fontWeight: 'bold', letterSpacing: '0.5px', color: '#333333' }}>{detail.name}</h3>
                                    <h6 style={{ color: '#9e9e9e' }}>@{detail.username}</h6>
                                </div>
                            </div>
                            <div className='d-flex flex-row align-items-center'>
                                <div id='search-table' className='search-table'>

                                </div>
                            </div>
                        </div>

                        <Form.Group as={Row} className="mt-4 mb-3">
                            <Form.Label column sm="12" className="mt-4">
                                Tanggal Lahir
                            </Form.Label>
                            <Col sm="12">
                                <Form.Control className="custom-form-control" type="date" name="tanggal_lahir" placeholder="Masukkan tanggal lahir" value={tgl_lahir} onChange={(e) => setTglLahir(e.target.value)} autoComplete="off" />
                            </Col>

                            <Form.Label column sm="12" className="mt-3">
                                Alamat Email
                            </Form.Label>
                            <Col sm="12">
                                <Form.Control className="custom-form-control" type="text" name="email" placeholder="Masukkan alamat email" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete="off" />
                            </Col>

                            <Form.Label column sm="12" className="mt-3">
                                Nomor ID Siswa
                            </Form.Label>
                            <Col sm="12">
                                <Form.Control className="custom-form-control" type="text" name="nomor_id" placeholder="Masukkan nomor id" value={nomor_id} onChange={(e) => setNomorID(e.target.value)} autoComplete="off" />
                            </Col>

                            <Form.Label column sm="12" className="mt-3">
                                Status Siswa
                            </Form.Label>
                            <Col sm="12">
                                <ReactSelect
                                    value={listStatus.filter((option) => option.value == status)}
                                    options={listStatus}
                                    placeholder="Pilih status siswa"
                                    onChange={(e) => selectedStatus(e)}
                                />
                            </Col>

                            <Form.Label column sm="12" className="mt-3">
                                Tahun Terakhir
                            </Form.Label>
                            <Col sm="12">
                                <ReactSelect
                                    value={listTahun.filter((option) => option.value == tahun)}
                                    options={listTahun}
                                    placeholder="Pilih tahun terakhir"
                                    onChange={(e) => selectedTahun(e)}
                                />
                            </Col>

                            <Form.Label column sm="12" className="mt-3">
                                Kelas
                            </Form.Label>
                            <Col sm="12">
                                <ReactSelect
                                    value={listKelas.filter((option) => option.value == kelas)}
                                    options={listKelas}
                                    placeholder="Pilih tahun terakhir"
                                    onChange={(e) => selectedKelas(e)}
                                />
                            </Col>

                            <Form.Label column sm="12" className="mt-3">
                                File Sertifikat
                            </Form.Label>
                            <Col sm="12">
                                {
                                    sertifikat.length > 0 ?
                                        sertifikat.map((row, i) => {
                                            return (
                                                <>
                                                    <div className="d-flex justify-space-between" style={{ border: '2px solid #E1E1E1', padding: '15px 20px 15px 20px', borderRadius: '15px' }}>
                                                        <div style={{ marginRight: '10px' }}>
                                                            <span><FontAwesomeIcon icon={faPaperclip} color="#828282" /> </span>
                                                        </div>
                                                        <div>
                                                            <div style={{ cursor: 'pointer', color: '#2F80ED' }} onClick={() => window.open(process.env.REACT_APP_BASE_URL_STORAGE_CERTIFICATE_USER + row?.hash_name, '_blank')}>{row.original_name}</div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                        : ''
                                }
                            </Col>

                            <Form.Label column sm="12" className="mt-3">
                                Nomor Telephone
                            </Form.Label>
                            <Col sm="12">
                                <Form.Control className="custom-form-control" type="number" name="phone" placeholder="Masukkan nomor id" value={nomor} onChange={(e) => setNomor(e.target.value)} autoComplete="off" />
                            </Col>


                            <Col sm="12">
                                <Button className="content-button-submit" variant="primary" type="submit">Simpan</Button>
                            </Col>
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Form>
        </div>
    );
};

export default DetailAkunAktif;
