import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faClock, faCloudUploadAlt, faFileUpload, faImage, faInbox, faPaperclip, faPlus, faSearchLocation, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Badge, Button, Card, Col, Container, Form, Image, Row, Spinner } from 'react-bootstrap';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';
import { Editor } from '@tinymce/tinymce-react';
import Swal from 'sweetalert2'
import { Link, useHistory } from 'react-router-dom';
import ServiceApi from '../../../api/MyApi';
import { formatedSelect } from "../../../helper/Helper";
import { useCallback } from 'react';
import ReactSelect from "react-select";
import { useDropzone } from 'react-dropzone';

const TambahPostingan = () => {
    const history = useHistory();
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadedImage, setUploadedImage] = useState([]);
    const [isFileLimit, setIsFileLimit] = useState(false);
    const [imageLimit, setImageLimit] = useState(10);
    const [fileLimit, setFileLimit] = useState(5);

    const [listSubKategori, setlistSubKategori] = useState([]);
    const [valueSubKategori, setValueSubKategori] = useState(null);
    const [valueTextEditor, setTextEditor] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleUploadImage = files => {
        const uploaded = [...uploadedImage];
        let limitExceeded = false;
        const items = [...files];
        try {
            items.some((file) => {
                if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                    if (file.size > 1e6) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: `Ukuran Foto ${file.name} terlalu besar! Hanya memperbolehkan sebesar 2MB`,
                        })
                        return true;
                    }
                    uploaded.push(file);
                    if (uploaded.length === imageLimit) setIsFileLimit(true);
                    if (uploaded.length > imageLimit) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: `Kamu hanya dapat mengunggah sebanyak ${imageLimit} gambar`,
                        })
                        document.getElementById('imageUpload').value = "";
                        setIsFileLimit(false);
                        limitExceeded = true;
                        return true;
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: `File sama dengan yang sudah diupload`,
                    })
                }
                return null;
            })
            if (!limitExceeded) setUploadedImage(uploaded)

        } catch (error) {
            console.log(error);
        }
    }

    const handleImageEvent = (e) => {
        const chosenFiles = e.target.files;
        handleUploadImage(chosenFiles);
    }

    const handleDeleteImage = (value) => {
        try {
            const uploaded = [...uploadedImage];
            uploaded.splice(value, 1);
            setUploadedImage(uploaded)
            if (uploaded.length !== fileLimit) setIsFileLimit(false);
        } catch (error) {
            console.log(error);
        }
    }

    const handleUploadFile = files => {
        const uploaded = [...uploadedFiles];
        let limitExceeded = false;
        const items = [...files];
        try {
            items.some((file) => {
                if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                    if (file.size > 4e6) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: `Ukuran File ${file.name} terlalu besar! Hanya memperbolehkan sebesar 4MB`,
                        })
                        return true;
                    }
                    uploaded.push(file);
                    if (uploaded.length === fileLimit) setIsFileLimit(true);
                    if (uploaded.length > fileLimit) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: `Kamu hanya dapat mengunggah sebanyak ${fileLimit} file`,
                        })
                        document.getElementById('fileUpload').value = "";
                        setIsFileLimit(false);
                        limitExceeded = true;
                        return true;
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: `File sama dengan yang sudah diupload`,
                    })
                }
                return null;
            })
            if (!limitExceeded) setUploadedFiles(uploaded)

        } catch (error) {
            console.log(error);
        }
    }

    const handleFilesEvent = (e) => {
        const chosenFiles = e.target.files;
        handleUploadFile(chosenFiles);
    }

    const handleDeleteFile = (value) => {
        try {
            const uploaded = [...uploadedFiles];
            uploaded.splice(value, 1);
            setUploadedFiles(uploaded)
            if (uploaded.length !== fileLimit) setIsFileLimit(false);
        } catch (error) {
            console.log(error);
        }
    }

    const handleKategori = (e) => {
        setValueSubKategori(null);
        const formData = new FormData();
        formData.append("param[0]", 'kategori');
        formData.append("category", e?.value);
        new ServiceApi().getListRefKsi(formData).then(
            (response) => {
                if (e?.value == 1) {
                    delete response.data?.kategori[0];
                }
                setlistSubKategori(formatedSelect(response.data.kategori, 'id', 'category'));
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const submitData = async (e) => {
        e.preventDefault();

        if (e.target.title.value === "" || e.target.category.value === "" || e.target.sub_category.value === "" || valueTextEditor === "") {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `Semua field wajib diisi`,
            })
        } else {
            setIsLoading(true);
            const formData = new FormData();
            formData.append("judul", e.target.title.value);
            formData.append("kategori", e.target.sub_category.value);
            formData.append("konten", valueTextEditor);
            for (const key of Object.keys(uploadedImage)) {
                formData.append('image[' + key + ']', uploadedImage[key])
            }
            for (const key of Object.keys(uploadedFiles)) {
                formData.append('attachment[' + key + ']', uploadedFiles[key])
            }

            new ServiceApi().addPostinganAdmin(formData)
                .then(
                    (response) => {
                        setIsLoading(false);
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'Berhasil membuat post',
                        })
                        history.replace('/posts_admin')
                    })
                .catch((error) => {
                    setIsLoading(false);
                    if (error.response) {
                        if (error.response.status === 422) {
                            Swal.fire({
                                icon: 'warning',
                                title: 'Kesalahan!',
                                html:
                                    (error.response.data.data.judul ? error.response.data.data.judul + '<br/>' : '')
                            })
                        }
                    }
                });
        }
    };

    const handleEditorChange = (e) => {
        setTextEditor(e.target.getContent());
    }

    return (
        <div className='main-animation'>
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div>
                    <Link className="content-link" to={{ pathname: `/posts_admin` }}><h3 className="content-title"><FontAwesomeIcon icon={faArrowLeft} size="sm" />&nbsp; New Post Admin</h3></Link>
                </div>
            </div>
            <div className="my-overlay" style={{ display: isLoading ? 'block' : 'none' }}>
                <div className="my-overlay-text">
                    <Spinner size='lg' animation="border" role="status">
                        <span className="visually-hidden">Mohon Tunggu...</span>
                    </Spinner><br />
                    <span>Mohon Tunggu...</span>
                </div>
            </div>
            <Form onSubmit={submitData}>
                <Card className="card-main-content">
                    <Card.Body>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="12">
                                Judul Post
                            </Form.Label>
                            <Col sm="12">
                                <Form.Control
                                    className="custom-form-control"
                                    type="text"
                                    name="title"
                                    placeholder="Masukkan judul post"
                                    autoComplete="off"
                                />
                            </Col>
                            <Col sm="12">
                                <br />
                                <label
                                    style={{
                                        backgroundColor: '#FFF7CA',
                                        color: '#BD8A3A',
                                        borderRadius: 5,
                                        padding: '10px 15px 10px 15px',
                                        cursor: 'pointer'
                                    }}
                                ><FontAwesomeIcon icon={faImage} />
                                    <input id='imageUpload' hidden accept="image/png, image/jpeg, image/jpg"
                                        multiple type="file" onChange={handleImageEvent} disabled={isFileLimit} />
                                </label>
                                <label
                                    style={{
                                        backgroundColor: '#E0ECFD',
                                        color: '#5B7DD6',
                                        borderRadius: 5,
                                        padding: '10px 15px 10px 15px',
                                        cursor: 'pointer',
                                        marginInlineStart: 20
                                    }}
                                ><FontAwesomeIcon icon={faPaperclip} />
                                    <input id='fileUpload' hidden accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        multiple type="file" onChange={handleFilesEvent} disabled={isFileLimit} />
                                </label>
                            </Col>
                            <Col sm="12">
                                <div style={{
                                    marginTop: 10,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    flexWrap: 'wrap',
                                    gap: 10,
                                }}>
                                    {uploadedImage.map((file, index) => (
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                        }} key={index}>
                                            <div>
                                                <Image
                                                    fluid
                                                    width={120}
                                                    height={120}
                                                    rounded
                                                    alt={'image-post-' + index}
                                                    src={URL.createObjectURL(file)}
                                                />
                                            </div>
                                            <Badge
                                                bg="danger"
                                                style={{ paddingInline: 5, cursor: 'pointer' }}
                                                onClick={() => handleDeleteImage(index)}
                                            >
                                                <FontAwesomeIcon icon={faTimes} size="2x" color='red' />
                                            </Badge>
                                        </div>
                                    ))}
                                </div>
                            </Col>
                            <Col sm="12">
                                {uploadedFiles.length > 0 && <br />}
                                {
                                    uploadedFiles.map((file, index) => {
                                        return (
                                            <div
                                                key={index}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: 'fit-content',
                                                    justifyContent: 'space-between',
                                                    backgroundColor: '#E0ECFD',
                                                    color: '#5B7DD6',
                                                    borderRadius: 5,
                                                    padding: '10px 15px 10px 15px',
                                                    margin: '0px 5px 5px 0px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <span>{file.name}</span>&nbsp;
                                                <FontAwesomeIcon icon={faTimes} onClick={() => handleDeleteFile(index)} />
                                            </div>
                                        )
                                    })
                                }
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="12">
                                Isi Post
                            </Form.Label>
                            <Col sm="12">
                                <Editor
                                    tinymceScriptSrc="/tinymce/tinymce.min.js"
                                    apiKey="d1h6va9igad06j5p5bckn9zvl3ikezdefxawmfljczrcfbq2"
                                    init={{
                                        width: '100%',
                                        height: '50vh',
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image',
                                            'charmap print preview anchor help',
                                            'searchreplace visualblocks code emoticons',
                                            'insertdatetime media table paste wordcount image'
                                        ],
                                        toolbar:
                                            'undo redo | styleselect | bold italic underline strikethrough subscript superscript | \
                                            alignleft aligncenter alignright | \
                                            bullist numlist outdent indent link image | emoticons help',
                                        content_style:
                                            "body { font-family: HANDotum; }",
                                        font_formats:
                                            "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; HANDotum=hANDotum; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
                                    }}
                                    onChange={handleEditorChange}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="12">
                                Pilih Kategori
                            </Form.Label>
                            <Col sm="12">
                                <ReactSelect
                                    placeholder={<div className="react-select-placeholder">Pilih Kategori...</div>}
                                    isSearchable={false}
                                    options={[
                                        { value: '1', label: 'Community' },
                                        { value: '2', label: 'Job & Career' },
                                        { value: '3', label: 'Hobby' },
                                        { value: '4', label: 'KSI' },
                                    ]}
                                    name="category"
                                    onChange={handleKategori}
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            borderRadius: '11px',
                                            boxShadow: '0 0 0 2px rgba(0, 0, 0, 0.15)',
                                            border: 'none'
                                        }),
                                    }}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="12">
                                Pilih Sub Kategori
                            </Form.Label>
                            <Col sm="12">
                                <ReactSelect
                                    placeholder={<div className="react-select-placeholder">Pilih Sub Kategori...</div>}
                                    isSearchable={false}
                                    options={listSubKategori}
                                    value={valueSubKategori}
                                    name="sub_category"
                                    onChange={(e) => setValueSubKategori(e)}
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            borderRadius: '11px',
                                            boxShadow: '0 0 0 2px rgba(0, 0, 0, 0.15)',
                                            border: 'none',
                                        }),
                                    }}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Col sm="12">
                                <Button className="content-button-submit" variant="primary" type="submit">Publikasi</Button>
                            </Col>
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Form>
        </div >
    );
};

export default TambahPostingan;
