import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import L from 'leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faClock, faCloudUploadAlt, faFileUpload, faInbox, faPlus, faSearchLocation } from '@fortawesome/free-solid-svg-icons'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { Button, Card, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import axios from 'axios';
import Swal from 'sweetalert2'
import * as AiIcons from 'react-icons/ai';
import * as BsIcons from 'react-icons/bs';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import ServiceApi from '../../api/MyApi';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Editor } from '@tinymce/tinymce-react';

const TambahEvents = () => {
    const history = useHistory();
    const [dataFiles, setFiles] = useState([]);
    const [dataFiles2, setFiles2] = useState([]);
    const [previewImages, setPreviewImages] = useState([]);
    const [valueTextEditor, setTextEditor] = useState("");

    useEffect(() => {
        return () => previewImages.forEach(file => URL.revokeObjectURL(file.preview));
    }, [])

    const onDrop = useCallback(acceptedFiles => {
        setFiles(acceptedFiles);
        setPreviewImages(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })))
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg', '.jpg']
        }
    });

    const { getRootProps: getRootAttachments, getInputProps: getInputAttachments, isDragActive: isDragActive2 } = useDropzone({
        onDrop: (acceptedFile) => {
            setFiles2(acceptedFile)
        },
        accept: {
            'application/pdf': ['.pdf'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/msword': ['.doc'],
        }
    });

    const handleEditorChange = (e) => {
        setTextEditor(e.target.getContent());
    }

    const submitData = async (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append('judul', e.target.elements.judul.value);
        formData.append('tanggal', e.target.elements.tanggal.value);
        formData.append('lokasi', e.target.elements.lokasi.value);
        if (dataFiles != null) {
            dataFiles.forEach((file) => formData.append('image[]', file));
        }
        if (dataFiles2 != null) {
            dataFiles2.forEach((file) => formData.append('attachment[]', file));
        }
        formData.append('konten', valueTextEditor);
        var messageError = [];
        const getValidationMessage = (myObject) => {
            for (let [k, v] of Object.entries(myObject)) {
                messageError.push({
                    message: myObject[k][0]
                })
            }
        }

        new ServiceApi().addEvents(formData)
            .then(response => {
                Swal.fire({
                    title: 'Sukses!',
                    html: '<i>' + response.data.message + '</i>',
                    icon: 'success',
                    confirmButtonColor: '#0058a8',
                }).then(function () {
                    history.push('/events')
                })
            }).catch(err => {
                const getMessage = err.response.data.data ? getValidationMessage(err.response.data.data) : '';
                var mess = messageError.map(mes => {
                    return mes.message + '<br/>'
                })
                Swal.fire({
                    title: 'Gagal!',
                    html: '<i>' + (err.response.data.data ? mess : err.response.data.message) + '</i>',
                    icon: 'error',
                    confirmButtonColor: '#0058a8',
                })
            });
    }

    return (
        <div className='main-animation'>
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div>
                    <Link className="content-link" to={{ pathname: `/events` }}><h3 className="content-title"><FontAwesomeIcon icon={faArrowLeft} size="sm" />&nbsp; New Event</h3></Link>
                </div>
            </div>

            <Form onSubmit={submitData}>
                <Card className="card-main-content">
                    <Card.Body>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="12">
                                Judul Event
                            </Form.Label>
                            <Col sm="12">
                                <Form.Control
                                    className="custom-form-control"
                                    type="text"
                                    name="judul"
                                    placeholder="Masukkan judul event"
                                    autoComplete="off"
                                    required
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="12">
                                Tanggal Event
                            </Form.Label>
                            <Col sm="12">
                                <Form.Control
                                    className="custom-form-control"
                                    type="date"
                                    name="tanggal"
                                    placeholder="Masukkan tanggal event"
                                    autoComplete="off"
                                    required
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="12">
                                Lokasi Event
                            </Form.Label>
                            <Col sm="12">
                                <Form.Control
                                    className="custom-form-control"
                                    type="text"
                                    name="lokasi"
                                    placeholder="Masukkan lokasi event"
                                    autoComplete="off"
                                    required
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="12">
                                Poster Event
                            </Form.Label>
                            <Col lg="12">
                                {
                                    _.isEmpty(dataFiles) ?
                                        <div className='drop-files-upload' {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            {
                                                isDragActive ?
                                                    <div className='d-flex flex-column justify-content-center align-items-center' style={{ paddingTop: 40, paddingBottom: 40 }}>
                                                        <FontAwesomeIcon className="cloud-icon" icon={faCloudUploadAlt} size="2x" />
                                                        <p>Taruh File disini...</p>
                                                    </div> :
                                                    <div className='d-flex flex-column justify-content-center align-items-center' style={{ paddingTop: 40, paddingBottom: 40 }}>
                                                        <FontAwesomeIcon className="cloud-icon" icon={faCloudUploadAlt} size="2x" />
                                                        <p>Klik atau taruh untuk memilih file</p>
                                                        <p style={{ fontSize: 13 }}><i>JPG, JPEG, PNG</i></p>
                                                    </div>
                                            }
                                        </div>
                                        :
                                        <>
                                            <div className="row d-flex justify-content-start">
                                                {
                                                    previewImages.map(file => (
                                                        <div key={file.name} className="col col-3">
                                                            <img
                                                                // className="m-4"
                                                                width="250"
                                                                height="400"
                                                                src={file.preview}
                                                                style={{
                                                                    borderRadius: '20px',
                                                                    margin: '10px 20px 0 0',
                                                                }}
                                                                onLoad={() => { URL.revokeObjectURL(file.preview) }}
                                                            />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            <Button
                                                className="text-center mt-4"
                                                variant="danger"
                                                onClick={() => setFiles([])}
                                                style={{
                                                    height: '40px',
                                                    width: '150px'
                                                }}
                                            >
                                                Batal
                                            </Button>
                                        </>
                                }
                                {
                                    _.isEmpty(dataFiles) ? '' : ''
                                }
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="12">
                                Attachments
                            </Form.Label>
                            <Col lg="12">
                                {
                                    _.isEmpty(dataFiles2) ?
                                        <div className='drop-files-upload' {...getRootAttachments()}>
                                            <input {...getInputAttachments()} />
                                            {
                                                isDragActive2 ?
                                                    <div className='d-flex flex-column justify-content-center align-items-center' style={{ paddingTop: 40, paddingBottom: 40 }}>
                                                        <FontAwesomeIcon className="cloud-icon" icon={faCloudUploadAlt} size="2x" />
                                                        <p>Taruh File disini...</p>
                                                    </div> :
                                                    <div className='d-flex flex-column justify-content-center align-items-center' style={{ paddingTop: 40, paddingBottom: 40 }}>
                                                        <FontAwesomeIcon className="cloud-icon" icon={faCloudUploadAlt} size="2x" />
                                                        <p>Klik atau taruh untuk memilih file</p>
                                                        <p style={{ fontSize: 13 }}><i>DOC, DOCX, PDF</i></p>
                                                    </div>
                                            }
                                        </div>
                                        :
                                        <>
                                            <div className="row d-flex justify-content-start">
                                                {
                                                    dataFiles2.map((x, key) => {
                                                        return (
                                                            <div key={key} className="d-flex flex-row">
                                                                <p>{key + 1}.&nbsp;</p>
                                                                <p>{x.name}</p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <Button
                                                className="text-center mt-2"
                                                variant="danger"
                                                onClick={() => setFiles2([])}
                                                style={{
                                                    height: '40px',
                                                    width: '150px'
                                                }}
                                            >
                                                Batal
                                            </Button>
                                        </>
                                }
                                {
                                    _.isEmpty(dataFiles2) ? '' : ''
                                }
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="12">
                                Deskripsi Singkat Event
                            </Form.Label>
                            <Col sm="12">
                                <Editor
                                    tinymceScriptSrc="/tinymce/tinymce.min.js"
                                    apiKey="d1h6va9igad06j5p5bckn9zvl3ikezdefxawmfljczrcfbq2"
                                    init={{
                                        width: '100%',
                                        height: '50vh',
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image',
                                            'charmap print preview anchor help',
                                            'searchreplace visualblocks code emoticons',
                                            'insertdatetime media table paste wordcount image'
                                        ],
                                        toolbar:
                                            'undo redo | styleselect | bold italic underline strikethrough subscript superscript | \
                                            alignleft aligncenter alignright | \
                                            bullist numlist outdent indent link image | emoticons help',
                                        content_style:
                                            "body { font-family: HANDotum; }",
                                        font_formats:
                                            "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; HANDotum=hANDotum; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
                                    }}
                                    onChange={handleEditorChange}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Col sm="12">
                                <Button className="content-button-submit" variant="primary" type="submit">Publikasi</Button>
                            </Col>
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Form>
        </div >
    );
};

export default TambahEvents;
