import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faClock, faPlus, faSearchLocation } from '@fortawesome/free-solid-svg-icons'
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton'
import moment from 'moment';
import Swal from 'sweetalert2'
import { Link, useHistory } from 'react-router-dom';
import ServiceApi from '../../api/MyApi';

const EditKelas = () => {
    const history = useHistory();

    const [namaSubKategori, setNamaSubKategori] = useState('');
    const [checkedMetode, setCheckedMetode] = useState(1);

    useEffect(() => {
        if (!history.location.state) {
            history.replace('/kategori')
        } else {
            setNamaSubKategori(history.location.state?.sub_kategori);
            setCheckedMetode(history.location.state?.tipe);
        }
    }, [history])

    const submitData = async (e) => {
        e.preventDefault();

        const data = {
            key: history.location.state?.id,
            'category': e.target.elements.sub_kategori.value,
            type: checkedMetode
        }

        new ServiceApi().updateKategori(data)
            .then(response => {
                Swal.fire({
                    title: 'Sukses!',
                    html: '<i>' + response.data.message + '</i>',
                    icon: 'success',
                    confirmButtonColor: '#0058a8',
                }).then(function () {
                    history.replace('/kategori')
                })
            }).catch(err => {
                Swal.fire({
                    title: 'Gagal!',
                    html: '<i>' + err.response.data.data.category ? err.response.data.data.category : '' + '</i>',
                    icon: 'error',
                    confirmButtonColor: '#0058a8',
                })
            });
    }

    const setCheckMetode = (e) => {
        setCheckedMetode(e)
    }

    return (
        <div className='main-animation'>
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div>
                    <Link className="content-link" to={{ pathname: `/kategori` }}><h3 className="content-title"><FontAwesomeIcon icon={faArrowLeft} size="sm" />&nbsp; Edit Sub Kategori</h3></Link>
                </div>
            </div>

            <Form onSubmit={submitData}>
                <Card className="card-main-content">
                    <Card.Body>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="12">
                                Nama Sub Kategori
                            </Form.Label>
                            <Col sm="12">
                                <Form.Control
                                    className="custom-form-control"
                                    type="text"
                                    name="sub_kategori"
                                    value={namaSubKategori}
                                    onChange={(e) => setNamaSubKategori(e.target.value)}
                                    placeholder="Masukkan nama sub kategori"
                                    autoComplete="off" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="12">
                                Tipe Kategori
                            </Form.Label>
                            <Col md="12" lg="12" sm="12" xl="12" className='d-flex flex-row' style={{ gap: 10 }}>
                                <div
                                    className='input-radio-custom'
                                    onClick={() => setCheckMetode(1)}
                                >
                                    <Form.Check
                                        inline
                                        checked={checkedMetode == 1}
                                        label="Community"
                                        name="type_1"
                                        type="radio"
                                        onChange={() => setCheckMetode(1)}
                                        id={`inline-type_1`}
                                    />
                                </div>
                                <div
                                    className='input-radio-custom'
                                    onClick={() => setCheckMetode(2)}
                                >
                                    <Form.Check
                                        inline
                                        checked={checkedMetode == 2}
                                        label="Job & Career"
                                        name="type_2"
                                        type="radio"
                                        onChange={() => setCheckMetode(2)}
                                        id={`inline-type_2`}
                                    />
                                </div>
                                <div
                                    className='input-radio-custom'
                                    onClick={() => setCheckMetode(3)}
                                >
                                    <Form.Check
                                        inline
                                        checked={checkedMetode == 3}
                                        label="Hobby"
                                        name="type_3"
                                        type="radio"
                                        onChange={() => setCheckMetode(3)}
                                        id={`inline-type_3`}
                                    />
                                </div>
                                <div
                                    className='input-radio-custom'
                                    onClick={() => setCheckMetode(4)}
                                >
                                    <Form.Check
                                        inline
                                        checked={checkedMetode == 4}
                                        label="KSI"
                                        name="type_4"
                                        type="radio"
                                        onChange={() => setCheckMetode(4)}
                                        id={`inline-type_4`}
                                    />
                                </div>
                            </Col>
                            <Col sm="12">
                                <Button className="content-button-submit" variant="primary" type="submit">Simpan</Button>
                            </Col>
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Form>
        </div>
    );
};

export default EditKelas;
