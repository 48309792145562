import { useEffect, useState } from 'react';

const useFCM = (props) => {
    const [payloadData, setPayloadData] = useState(null);

    useEffect(() => {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.addEventListener("message", (event) => {
                setPayloadData(event?.data?.firebaseMessaging?.payload);
            });
        } else {
            console.log("serviceWorker not in navigator");
        }
    });

    return getReturnValues(payloadData);
};

const getReturnValues = (event) => {
    const _payload = event;
    return [_payload];
};

export default useFCM;