import moment from 'moment';
import ListGroup from 'react-bootstrap/ListGroup';
import { useHistory, useLocation } from 'react-router-dom';
import ServiceApi from '../../api/MyApi';

const ItemNotification = ({ data }) => {
    const history = useHistory();

    const onNotifClick = (e) => {
        const formData = new FormData();
        formData.append('key', e?.encrypted_id);
        new ServiceApi().readNotification(formData).then((res) => {
            history.push(res.data.routes_to);
        })
    }
    console.log(data);
    return (
        <ListGroup.Item action onClick={() => onNotifClick(data)} style={{backgroundColor: data?.is_viewed ? '' : '#F6E8D2'}}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <img
                        src={data?.avatar ? process.env.REACT_APP_BASE_URL_STORAGE_AVATAR_USER + data?.avatar : 'https://www.w3schools.com/howto/img_avatar.png'}
                        width={56}
                        height={56}
                        style={{ borderRadius: '50%', cursor: 'pointer' }}
                    />
                    <div style={{ marginLeft: 10 }}>
                        <div style={{ fontWeight: 'bold' }}>{data?.name}</div>
                        <div style={{ fontSize: 12, color: 'rgba(119, 119, 119, 1)' }}>{data?.message}</div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'start' }}>
                    <div style={{ fontSize: 12, color: 'rgba(119, 119, 119, 1)' }}>{moment(data?.waktu).fromNow()}</div>
                </div>
            </div>
        </ListGroup.Item>
    )
};

export default ItemNotification;