import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import L from 'leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCalendar, faClock, faComment, faDownload, faEye, faMapMarkedAlt, faMapMarkerAlt, faPaperclip, faPaperPlane, faPencilAlt, faPlus, faSearchLocation, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { Badge, Button, Card, Col, Container, Form, Image, Modal, Row } from 'react-bootstrap';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import axios from 'axios';
import Swal from 'sweetalert2'
import * as AiIcons from 'react-icons/ai';
import * as BsIcons from 'react-icons/bs';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom'; import ServiceApi from '../../api/MyApi';
import ReactSelect from 'react-select';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const Profile = () => {
    const history = useHistory();
    const location = useLocation();
    const myparam = location.state;
    const [nama, setNama] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [avatar, setAvatar] = useState('');
    const [dataFiles, setFiles] = useState([]);
    const [previewImages, setPreviewImages] = useState([]);

    useEffect(() => {
        return () => previewImages.forEach(file => URL.revokeObjectURL(file.preview));
    }, [])

    useEffect(() => {
        new ServiceApi().getDetailProfile().then(x => {
            setNama(x.data.data.nama);
            setUsername(x.data.data.username);
            setEmail(x.data.data.email);
            setAvatar(x.data.data.avatar);
        }).catch((err) => {
        })
    }, [])

    const onDrop = useCallback(acceptedFiles => {
        setFiles(acceptedFiles);
        setPreviewImages(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })))
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg', '.jpg']
        }
    });

    const submitData = async (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append('nama', nama);
        formData.append('email', email);
        formData.append('password', e.target.elements.password.value);
        formData.append('password_confirmation', e.target.elements.password_confirmation.value);
        var messageError = [];
        const getValidationMessage = (myObject) => {
            for (let [k, v] of Object.entries(myObject)) {
                messageError.push({
                    message: myObject[k][0]
                })
            }
        }

        if (dataFiles != null) {
            dataFiles.forEach((file) => formData.append('avatar', file));
        }

        new ServiceApi().updateProfile(formData)
            .then(response => {
                Swal.fire({
                    title: 'Sukses!',
                    html: '<i>' + response.data.message + '</i>',
                    icon: 'success',
                    confirmButtonColor: '#0058a8',
                }).then(function () {
                    window.location.reload();
                })
            }).catch(err => {
                const getMessage = err.response.data.data ? getValidationMessage(err.response.data.data) : '';
                var mess = messageError.map(mes => {
                    return mes.message + '<br/>'
                })
                Swal.fire({
                    title: 'Gagal!',
                    html: '<i>' + (err.response.data.data ? mess : err.response.data.message) + '</i>',
                    icon: 'error',
                    confirmButtonColor: '#0058a8',
                }).then(function () {
                    window.location.reload();
                })
            });
    }


    return (
        <div className='main-animation'>
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div>
                    <Link className="content-link" to={{ pathname: `/` }}><h3 className="content-title"><FontAwesomeIcon icon={faArrowLeft} size="sm" />&nbsp; Update Profile Admin</h3></Link>
                </div>
            </div>

            <Form onSubmit={submitData}>
                <Card className="card-main-content">
                    <Card.Body>
                        <div className='head-table d-flex justify-content-center align-items-center'>
                            <div id='size-table' className='size-table'>
                                {
                                    _.isEmpty(dataFiles) ?
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            {
                                                avatar == null ? <div style={{ width: '130px', height: '130px', backgroundColor: '#828282', borderRadius: '50%' }}></div>
                                                    :
                                                    <img
                                                        src={process.env.REACT_APP_BASE_URL_STORAGE_AVATAR_USER + avatar}
                                                        width="130"
                                                        height="130"
                                                        style={{
                                                            borderRadius: '50%'
                                                        }}
                                                    />
                                            }
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <Badge
                                                    className="warning"
                                                    bg="warning"
                                                    text="dark"
                                                    style={{ marginTop: '20px', cursor: 'pointer' }}
                                                >
                                                    ganti gambar
                                                </Badge>
                                            </div>
                                        </div>
                                        :
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            {
                                                previewImages.map(file => (
                                                    <>
                                                        <div key={file.name}>
                                                            <img
                                                                width="130"
                                                                height="130"
                                                                src={file.preview}
                                                                style={{
                                                                    borderRadius: '50%'
                                                                }}
                                                                onLoad={() => { URL.revokeObjectURL(file.preview) }}
                                                            />
                                                        </div>
                                                        <div>
                                                            <Badge
                                                                className="danger text-center"
                                                                bg="danger"
                                                                text="dark"
                                                                style={{ marginTop: '20px', cursor: 'pointer' }}
                                                                onClick={() => setFiles([])}
                                                            >
                                                                batal
                                                            </Badge>
                                                        </div>
                                                    </>
                                                ))
                                            }
                                        </div>
                                }
                            </div>
                        </div>

                        <Form.Group as={Row} className="mt-4 mb-3">
                            <Col lg="12">
                                <h5 style={{ color: '#BD8A3A', fontWeight: 'bold' }}>Informasi General</h5>
                                <hr style={{ color: '#828282' }} />
                            </Col>

                            <Form.Label column sm="12" className="mt-3">
                                Username
                            </Form.Label>
                            <Col sm="12">
                                <Form.Control className="custom-form-control" type="text" value={username} autoComplete="off" readOnly disabled />
                            </Col>

                            <Form.Label column sm="12" className="mt-3">
                                Nama
                            </Form.Label>
                            <Col sm="12">
                                <Form.Control className="custom-form-control" type="text" name="nama" placeholder="Masukkan nama" value={nama} onChange={(e) => setNama(e.target.value)} autoComplete="off" />
                            </Col>

                            <Form.Label column sm="12" className="mt-3">
                                Email
                            </Form.Label>
                            <Col sm="12" className="mb-3">
                                <Form.Control className="custom-form-control" type="text" name="email" placeholder="Masukkan alamat email" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete="off" />
                            </Col>

                            <Col lg="12" className="mt-4">
                                <h5 style={{ color: '#BD8A3A', fontWeight: 'bold' }}>Ubah Password</h5>
                                <hr style={{ color: '#828282' }} />
                            </Col>

                            <Form.Label column sm="12" className="mt-3">
                                Password
                            </Form.Label>
                            <Col sm="12">
                                <Form.Control className="custom-form-control" type="password" name="password" placeholder="Masukkan password baru" autoComplete="off" />
                            </Col>

                            <Form.Label column sm="12" className="mt-3">
                                Konfirmasi Password
                            </Form.Label>
                            <Col sm="12" className="mb-3">
                                <Form.Control className="custom-form-control" type="password" name="password_confirmation" placeholder="Konfirmasi password baru" autoComplete="off" />
                            </Col>

                            <Col sm="12">
                                <Button className="content-button-submit" variant="primary" type="submit">Simpan</Button>
                            </Col>
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Form>
        </div >
    );
};

export default Profile;
