import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton'
import moment from 'moment';
import Swal from 'sweetalert2'
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import ServiceApi from '../../api/MyApi';

const UpdateInterest = () => {
    const history = useHistory();
    const location = useLocation();
    const myparam = location.state;
    const [input, setInput] = useState('');

    useEffect(() => {
        setInput(myparam.interest);
    }, [myparam])

    const updateData = async (e) => {
        e.preventDefault();

        const data = {
            'key': myparam.id,
            'interest': e.target.elements.interest.value
        }

        new ServiceApi().updateInterest(data)
            .then(response => {
                Swal.fire({
                    title: 'Sukses!',
                    html: '<i>' + myparam.interest + ' berhasil diupdate</i>',
                    icon: 'success',
                    confirmButtonColor: '#0058a8',
                }).then(function () {
                    history.push('/interest')
                })
            }).catch(err => {
                Swal.fire({
                    title: 'Gagal!',
                    html: '<i>' + (err.response.data.data.interest ? 'interest kosong' + '<br/>' : '') + (err.response.data.data.interest ? err.response.data.data.interest : '') + '</i>',
                    icon: 'error',
                    confirmButtonColor: '#0058a8',
                })
            });
    }

    if (!myparam) return <Redirect to="/interest" />

    return (
        <div className='main-animation'>
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div>
                    <Link className="content-link" to={{ pathname: `/interest` }}><h3 className="content-title"><FontAwesomeIcon icon={faArrowLeft} size="sm" />&nbsp; Update Interest</h3></Link>
                </div>
            </div>

            <Form onSubmit={updateData}>
                <Card className="card-main-content">
                    <Card.Body>
                        <Form.Group as={Row} className="mt-3 mb-3">
                            <Form.Label column sm="3">
                                Nama Interest
                            </Form.Label>
                            <Col sm="12">
                                <Form.Control className="custom-form-control" type="text" value={input} name="interest" placeholder="Masukkan nama interest" onChange={(e) => setInput(e.target.value)} autoComplete="off" required />
                            </Col>
                            <Col sm="12">
                                <Button className="content-button-submit" variant="primary" type="submit">Simpan</Button>
                            </Col>
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Form>
        </div>
    );
};

export default UpdateInterest;
