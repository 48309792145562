import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as FiIcons from "react-icons/fi";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";

import Logo from "../../img/logo-gabung-ksi.png";
import { Form, NavbarBrand } from "react-bootstrap";
import Swal from "sweetalert2";
import useLocalStorage from "../../hooks/useLocalStorage";
import useWindowDimensions from '../../hooks/useWindowDimensions';
import useFCM from '../../hooks/useFCM';
import Dropdown from 'react-bootstrap/Dropdown';
import ListGroup from 'react-bootstrap/ListGroup';
import ItemNotification from "./ItemNotification";
import ServiceApi from "../../api/MyApi";
import _ from "lodash";

const Nav = styled.div`
  background: #fff;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
  width: '100%';
  box-shadow: 0px 0px 30px rgba(201, 166, 111, 0.3);
`;

const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  margin: 0px 0px 0px 0px;
  background: #C9A66F;
  width: 280px;
  height: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  transition: 350ms;
  left: ${({ sidebar }) => (sidebar ? "0px" : "-100%")};
  z-index: 10;
`;
//left: ${({ sidebar }) => (sidebar ? '10' : '-100%')};

const SidebarWrap = styled.div`
  width: 100%;
`;

const SidebarListMenu = styled.div`
  height: 70vh;
  background: #C9A66F;
  z-index: -1;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Search = styled.div`
  margin: 25px 15px 25px 15px;
  padding: 5px;
  display: flex;
  align-items: center;
  width: 30%;
`;

const SearchBar = styled.input`
  padding: 1rem 1rem 1rem 2.5rem;
  border-radius: 12px;
  border: solid 0px;
  background: rgba(238, 238, 238, 1);
  height: 50px;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 15px;
  align-items: center;
`;

const Sidebar = ({ parentCallback }) => {
  const history = useHistory();
  const [sidebar, setSidebar] = useState(true);
  const [remember, setRemember] = useLocalStorage();
  const [showIcon, setShowIcon] = useState(false);
  const [countNotification, setCountNotification] = useState(0);
  const [listNotifikasi, setListNotifikasi] = useState([]);
  const { height, width } = useWindowDimensions();
  const [_payload] = useFCM();
  const [avatar, setAvatar] = useState('');

  useEffect(() => {
    if (width < 600) {
      setShowIcon(true);
      setSidebar(false)
      parentCallback(false);
    } else {
      setShowIcon(false);
      setSidebar(true)
      parentCallback(true);
    }
  }, [width]);

  const showSidebar = () => {
    setSidebar(true);
  };

  const closeSidebar = () => {
    setSidebar(false);
  };

  const showSwall = () => {
    Swal.fire({
      title: 'Logout',
      text: 'Konfirmasi Keluar',
      icon: 'question',
      showCancelButton: true,
      showDenyButton: true,
      denyButtonColor: '#C9A66F',
      denyButtonText: `Edit Profile`,
      cancelButtonText: 'Batal',
    }).then((result) => {
      if (result.isConfirmed) {
        setRemember();
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location.reload();
      } else if (result.isDenied) {
        history.push('/profile')
      }
    })
  }

  useEffect(() => {
    new ServiceApi().getListNotifikasi('page=1').then((res) => {
      setListNotifikasi(res.data.data);
    });
  }, []);

  useEffect(async () => {
    const isMounted = true;
    await new ServiceApi().getCountNotification().then((res) => {
      if (isMounted) {
        setCountNotification(res?.data?.notif);
      }
    });
    return () => { isMounted = false };
  }, [_payload]);

  useEffect(() => {
    new ServiceApi().getDetailProfile().then(x => {
      setAvatar(x.data.data.avatar);
    }).catch((err) => {
    })
  }, [])

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav style={sidebar ? { margin: '0', transition: '350ms' } : { margin: '0', transition: '350ms' }}>
          {
            showIcon ?
              <NavIcon to="#">
                {
                  sidebar ? <FaIcons.FaWindowClose onClick={closeSidebar} color={"#000"} /> : <FaIcons.FaBars onClick={showSidebar} color={"#000"} />
                }
              </NavIcon>
              :
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignContent: 'center', alignItems: 'center', marginLeft: 50 }}>
                <NavbarBrand>
                  <img
                    src={Logo}
                    width="250"
                    height="52"
                    className="d-inline-block"
                    alt="navbar logo"
                  />
                </NavbarBrand>

              </div>
          }
          <RightContent>
            <FiIcons.FiHome color="rgba(119, 119, 119, 1)" size={32} />
            <div style={{ width: 24 }}></div>
            <div className="position-relative" style={{
              cursor: 'pointer',
            }}>
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" as={'div'}>
                  <FiIcons.FiBell color="rgba(119, 119, 119, 1)" size={32} />
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {countNotification}
                    <span className="visually-hidden">unread messages</span>
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu as={'div'} className="dropdown-menu-v2" style={{
                  width: '300px !important',
                }}>
                  <ListGroup variant="flush">
                    {
                      _.isEmpty(listNotifikasi) ?
                        <ListGroup.Item>
                          <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">Tidak ada notifikasi</h5>
                          </div>
                        </ListGroup.Item>
                        :
                        listNotifikasi.map((item, index) => {
                          return (
                            <ItemNotification key={index} data={item} />
                          )
                        })
                    }
                  </ListGroup>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div style={{ width: 24 }}></div>
            <div>
              {
                avatar == null ?
                  <img
                    src="https://www.diethelmtravel.com/wp-content/uploads/2016/04/bill-gates-wealthiest-person.jpg"
                    width={56}
                    height={56}
                    style={{ borderRadius: '50%', cursor: 'pointer' }}
                    onClick={showSwall}
                  />
                  :
                  <img
                    src={process.env.REACT_APP_BASE_URL_STORAGE_AVATAR_USER + avatar}
                    width={56}
                    height={56}
                    style={{ borderRadius: '50%', cursor: 'pointer' }}
                    onClick={showSwall}
                  />
              }
            </div>
          </RightContent>
        </Nav>
        <SidebarNav sidebar={sidebar}>
          <SidebarWrap>
            <div className="side-header">
              <p>
                Admin
              </p>
            </div>
            <SidebarListMenu>
              {SidebarData.map((item, index) => {
                return <SubMenu item={item} key={index} parentCallback={closeSidebar} />;
              })}
            </SidebarListMenu>
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  );
};

export default Sidebar;
