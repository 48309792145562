import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import _ from "lodash";

import LoginView from '../pages/Login';

import DashboardView from "../pages/Dashboard";

//KSI

//PROFILE
import ProfileView from "../pages/profile/Profile";

//APPROVAL
import ApprovalView from "../pages/approval/Approval";
import DetailApproval from "../pages/approval/DetailApproval";

//USER AKTIF
import UserAktifView from "../pages/user/aktif/AkunAktif";
import DetailUserAktifView from "../pages/user/aktif/DetailAkunAktif";

//USER TIDAK AKTIF
import UserTidakAktifView from "../pages/user/tidak_aktif/AkunTidakAktif";

//INTEREST
import InterestView from "../pages/interest/DaftarInterest";
import TambahInterestView from "../pages/interest/TambahInterest";
import DetailInterestView from "../pages/interest/DetailInterest";
import UpdateInterestView from "../pages/interest/UpdateInterest";

//EVENTS
import EventsView from "../pages/events/DaftarEvent";
import TambahEventsView from "../pages/events/TambahEvents";
import DetailEventsView from "../pages/events/DetailEvent";
import EditEventsView from "../pages/events/EditEvent";

//KELAS
import ClassView from "../pages/kelas/DaftarKelas";
import TambahClassView from "../pages/kelas/TambahKelas";
import EditClassView from "../pages/kelas/EditKelas";

//TahunBelajar
import TahunBelajarView from "../pages/tahun_belajar/DaftarTahunBelajar";
import TambahTahunBelajarView from "../pages/tahun_belajar/TambahTahunBelajar";
import EditTahunBelajarView from "../pages/tahun_belajar/EditTahunBelajar";

//KATEGORI
import KategoriView from "../pages/kategori/DaftarKategori"
import TambahKategoriView from "../pages/kategori/TambahKategori"
import EditKategoriView from "../pages/kategori/EditKategori"

//POSTINGAN USER
import PostinganUserView from "../pages/posts/user/DaftarPostingan";
import DetailPostinganUserView from "../pages/posts/user/DetailPostingan";

//POSTINGAN ADMIN
import PostinganAdminView from "../pages/posts/admin/DaftarPostingan"
import DetailPostinganAdminView from "../pages/posts/admin/DetailPostingan"
import TambahPostinganAdminView from "../pages/posts/admin/TambahPostingan"
import EditPostinganAdminView from "../pages/posts/admin/EditPostingan"

//MANAJEMEN USER
import ManajemenUserView from "../pages/master/manajemen_user/ManajemenUser";
import DetailManajemenUserView from "../pages/master/manajemen_user/DetailManajemenUser";
import TambahManajemenUserView from "../pages/master/manajemen_user/TambahManajemenUser";
import EditManajemenUserView from "../pages/master/manajemen_user/EditManajemenUser";

import useLocalStorage from "../hooks/useLocalStorage";

import PresenceContext from "../context/OmbudsmanContext";

import "moment/locale/id";
import Sidebar from "../components/SideBar/Sidebar";
import { firebaseCloudMessaging } from '../utils/webPush';
import PushNotificationLayout from "../components/PushNotificationLayout";

const AppRouter = (props) => {
  const [remember, setRemember] = useLocalStorage("remember_me", []);
  const [sidebar, setSidebar] = useState(false);

  const callbackSideBar = (cond) => {
    setSidebar(cond)
  }

  useEffect(() => {
    const _fireBase = async () => {
      await firebaseCloudMessaging.init();
    }
    _fireBase();
  }, [props]);

  return (
    <BrowserRouter>
      <Switch>
        {_.size(remember) > 0 ? (
          <React.Fragment>
            <PushNotificationLayout>
              <Sidebar parentCallback={callbackSideBar} />
              <div style={{ width: "100%" }} className="main-animation">
                <main id="page-wrap" className={!sidebar ? "main-content wrapper-hide" : "main-content wrapper"}>
                  <PresenceContext.Provider value={{ remember, setRemember }}>
                    <Route
                      component={() => <Redirect to="/dashboard" />}
                      path="/"
                      exact
                    />
                    {/* 
                  <Route
                    component={() => <Redirect to="/dashboard" />}
                    path="/dashboard"
                    exact
                  /> */}
                    <Route component={DashboardView} path="/dashboard" />

                    {/* PROFILE */}
                    <Route component={ProfileView} path="/profile" exact />

                    {/* APPROVAL */}
                    <Route component={ApprovalView} path="/approval" exact />
                    <Route component={DetailApproval} path="/approval/detail" />

                    {/* USER AKTIF */}
                    <Route component={UserAktifView} path="/user/aktif" exact />
                    <Route component={DetailUserAktifView} path="/user/aktif/detail" exact />

                    {/* USER TIDAK AKTIF */}
                    <Route component={UserTidakAktifView} path="/user/tidak_aktif" exact />

                    {/* EVENTS */}
                    <Route component={EventsView} path="/events" exact />
                    <Route component={TambahEventsView} path="/events/tambah" exact />
                    <Route component={DetailEventsView} path="/events/detail" exact />
                    <Route component={EditEventsView} path="/events/edit" />

                    {/* INTEREST */}
                    <Route component={InterestView} path="/interest" exact />
                    <Route component={TambahInterestView} path="/interest/tambah" exact />
                    <Route component={DetailInterestView} path="/interest/detail" exact />
                    <Route component={UpdateInterestView} path="/interest/update" exact />

                    {/* KELAS */}
                    <Route component={ClassView} path="/kelas" exact />
                    <Route component={TambahClassView} path="/kelas/tambah" />
                    <Route component={EditClassView} path="/kelas/edit/:id" />

                    {/* TAHUN BELAJAR */}
                    <Route component={TahunBelajarView} path="/tahun-belajar" exact />
                    <Route component={TambahTahunBelajarView} path="/tahun-belajar/tambah" />
                    <Route component={EditTahunBelajarView} path="/tahun-belajar/edit/:id" />

                    {/* MANAJEMEN USER */}
                    <Route component={ManajemenUserView} path="/master/manajemen_user" exact />
                    <Route component={DetailManajemenUserView} path="/master/manajemen_user/detail" exact />
                    <Route component={TambahManajemenUserView} path="/master/manajemen_user/tambah" exact />
                    <Route component={EditManajemenUserView} path="/master/manajemen_user/edit" exact />

                    {/* KATEGORI */}
                    <Route component={KategoriView} path="/kategori" exact />
                    <Route component={TambahKategoriView} path="/kategori/tambah" />
                    <Route component={EditKategoriView} path="/kategori/edit/:id" />

                    {/* POSTINGAN USER */}
                    <Route component={PostinganUserView} path="/posts_user" exact />
                    <Route component={DetailPostinganUserView} path="/posts_user/detail" exact />

                    {/* POSTINGAN ADMIN */}
                    <Route component={PostinganAdminView} path="/posts_admin" exact />
                    <Route component={DetailPostinganAdminView} path="/posts_admin/detail" exact />
                    <Route component={TambahPostinganAdminView} path="/posts_admin/tambah" exact />
                    <Route component={EditPostinganAdminView} path="/posts_admin/edit" exact />

                    {/* <Route component={() => <Redirect to="/" />} /> */}
                  </PresenceContext.Provider>
                </main>
              </div>
            </PushNotificationLayout>
          </React.Fragment>
        ) : (
          <PresenceContext.Provider value={{ remember, setRemember }}>
            <Route component={() => <Redirect to="/" />} />
            <Route component={LoginView} path="/" exact={true} />
            <Route component={() => <Redirect to="/" />} />
          </PresenceContext.Provider>
        )}
        <Route component={() => <Redirect to="/" />} />
        <Route component={LoginView} path="/" exact={true} />
        <Route component={() => <Redirect to="/" />} />
      </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;
