import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCaretDown,
  faCheckCircle,
  faClock,
  faEdit,
  faEllipsisH,
  faEye,
  faPlus,
  faSearchLocation,
  faTimesCircle,
  faTrash
} from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Pagination,
  Row
} from 'react-bootstrap'
import _ from 'lodash'
import moment from 'moment'
import Swal from 'sweetalert2'
import * as FaIcons from 'react-icons/fa'
import { Link, useHistory } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import ServiceApi from '../../api/MyApi'
import DataTable from 'react-data-table-component'
import DataTableExtensions from 'react-data-table-component-extensions'


const Approval = () => {
  const style = {
    color: 'white',
    fontWeight: 600,
    fontSize: 16,
    strokeWidth: 50
  }
  const headerStyle = {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    padding: '15px 19px 15px 19px !important',
    color: 'rgba(44, 45, 48, 0.75)'
  }
  const cellStyle = {
    color: 'rgba(82, 83, 88, 1)',
    padding: '20px 0px 20px 0px',
    fontSize: '16px',
    verticalAlign: 'bottom !important',
    borderTop: '1px solid hsl(0, 0 %, 87 %) !important',
    borderBottom: '0px solid hsl(0, 0 %, 87 %) !important',

  }
  const history = useHistory();
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [dataCount, setDataCount] = useState(0)
  const [listApproval, setListApproval] = useState([])
  const [selectedRows, setSelectedRows] = React.useState([])
  const [jmlSelected, setJmlSelected] = React.useState([])
  const [username, setUsername] = React.useState('')
  const [showReject, setShowReject] = useState(false);

  useEffect(() => {
    viewData()
  }, [])

  const viewData = async () => {
    const param = `page=${currentPage}&length=${perPage}&search=`
    await new ServiceApi()
      .getListApproval(param)
      .then(x => {
        setDataCount(x.data.total_data)
        setListApproval(x.data.user)
        setPageCount(Math.ceil(x.data.total_data / perPage))
      })
      .catch(err => { })
  }

  function handlePerPage(e) {
    setPerPage(e.target.value)
    const param = `page=${currentPage}&length=${e.target.value}&search=`
    new ServiceApi()
      .getListApproval(param)
      .then(x => {
        setListApproval(x.data.user)
        setPageCount(Math.ceil(x.data.total_data / e.target.value))
      })
      .catch(err => { })
  }

  async function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage + 1)
    const param = `page=${selectedPage + 1}&length=${perPage}&search=`
    await new ServiceApi()
      .getListApproval(param)
      .then(x => {
        setListApproval(x.data.user)
      })
      .catch(err => { })
  }

  const searchData = async e => {
    const param = `page=${currentPage}&length=${perPage}&search=${e.target.value}`
    await new ServiceApi()
      .getListApproval(param)
      .then(x => {
        setDataCount(x.data.total_data)
        setListApproval(x.data.user)
        setPageCount(Math.ceil(x.data.total_data / perPage))
      })
      .catch(err => { })
  }

  const handleRowSelected = React.useCallback(state => {
    setJmlSelected(state.selectedRows.filter(i => i).length)
    setSelectedRows(state.selectedRows);
  }, []);

  const activeUser = () => {
    let formData = new FormData();

    if (selectedRows != null) {
      selectedRows.forEach((x) => formData.append('username[]', x.username));
    }

    const username = selectedRows.map(row => {
      return '@' + row.username + '&nbsp;'
    })

    Swal.fire({
      title: 'Perhatian!',
      html: '<i>Anda yakin ingin approve <b>' + username + '</b> ?</i>',
      showCancelButton: true,
      confirmButtonText: 'Approve',
      cancelButtonText: 'Batal',
      confirmButtonColor: '#C9A66F',
      cancelButtonColor: '#828282',
    }).then(function (response) {
      if (response.isConfirmed) {
        new ServiceApi().addApproval(formData)
          .then(response => {
            Swal.fire({
              title: 'Sukses!',
              html: '<i>' + response.data.message + '</i>',
              icon: 'success',
              confirmButtonColor: '#0058a8',
            })
            viewData()
          }).catch(err => {
            Swal.fire({
              title: 'Gagal!',
              html: '<i>' + err.response.data.message + '</i>',
              icon: 'error',
              confirmButtonColor: '#0058a8',
            })
          })
      }
    })
  }

  const approvalData = (x) => {
    const data = {
      'username': x?.username
    }

    Swal.fire({
      title: 'Perhatian!',
      html: '<i>Anda yakin ingin approve <b> @' + x?.username + '</b> ?</i>',
      showCancelButton: true,
      confirmButtonText: 'Approve',
      cancelButtonText: 'Batal',
      confirmButtonColor: '#C9A66F',
      cancelButtonColor: '#828282',
    }).then(function (response) {
      if (response.isConfirmed) {
        new ServiceApi().addApproval(data)
          .then(response => {
            Swal.fire({
              title: 'Sukses!',
              html: '<i>' + response.data.message + '</i>',
              icon: 'success',
              confirmButtonColor: '#0058a8',
            })
            viewData();
          }).catch(err => {
            Swal.fire({
              title: 'Gagal!',
              html: '<i>' + err.response.data.message + '</i>',
              icon: 'error',
              confirmButtonColor: '#0058a8',
            })
          })
      }
    })
  }

  const handleCloseReject = () => {
    setShowReject(false)
    setUsername('')
  };
  const handleShowReject = (x) => {
    setShowReject(true)
    setUsername(x.username)
  };

  const rejectData = async (e) => {
    e.preventDefault();

    const data = {
      'username': username,
      'alasan': e.target.elements.alasan.value,
    }

    new ServiceApi().rejectApproval(data)
      .then(response => {
        Swal.fire({
          title: 'Sukses!',
          html: '<i>' + response.data.message + '</i>',
          icon: 'success',
          confirmButtonColor: '#0058a8',
        }).then(function () {
          setShowReject(false)
          viewData();
        })
      }).catch(err => {
        Swal.fire({
          title: 'Gagal!',
          html: '<i>' + err.response.data.message + '</i>',
          icon: 'error',
          confirmButtonColor: '#0058a8',
        })
      });
  }

  return (
    <div className='main-animation'>
      <div className='d-flex flex-row justify-content-between align-items-center'>
        <div>
          <h3 className='content-title'>Approval</h3>
        </div>
      </div>

      <Card className='card-main-content'>
        <Card.Body>
          <div className='head-table'>
            <div id='size-table' className='size-table'>
              <div>Lihat &nbsp;</div>
              <div>
                <Form.Control
                  className='select-row-table'
                  name='per_page'
                  as='select'
                  onChange={e => handlePerPage(e)}
                >
                  <option value='5'>5</option>
                  <option value='10' selected>
                    10
                  </option>
                  <option value='20'>20</option>
                  <option value='25'>25</option>
                </Form.Control>
              </div>
              <div>&nbsp; data</div>
            </div>
            <div className='d-flex flex-row align-items-center'>
              <div className='mt-4 mb-4'>
                <Button className='content-button' disabled={jmlSelected == 0 ? true : false} onClick={activeUser}>Approve</Button>
              </div>
              <div id='search-table' className='search-table'>
                <FaIcons.FaSearch
                  style={{ marginLeft: '1rem', position: 'absolute' }}
                  color='#2c2d3040'
                />
                <Form.Control
                  type='text'
                  placeholder='Cari'
                  onChange={e => searchData(e)}
                />
              </div>
            </div>
          </div>
          <p>{jmlSelected == 0 ? '' : jmlSelected + ' Terpilih'}</p>
          <div id='content-table' className='content-table'>
            <div className='scroll-me'>
              <DataTableExtensions
                columns={[
                  {
                    name: <div style={headerStyle}>#</div>,
                    selector: (row, index) => <div style={cellStyle}>{index + 1}</div>,
                    sortable: false,
                    width: '50px',
                  },
                  {
                    name: <div style={headerStyle}>Nomor ID</div>,
                    selector: (row) => <div style={cellStyle}>{row.nomor_id ?? '-'}</div>,
                    sortable: false
                  },
                  {
                    name: <div style={headerStyle}>Nama Lengkap</div>,
                    selector: (row) => <div style={cellStyle}>{row.name ?? '-'}</div>,
                    sortable: false,
                  },
                  {
                    name: <div style={headerStyle}>Username</div>,
                    selector: (row) => <div style={cellStyle}>{row.username ?? '-'}</div>,
                    sortable: false,
                    center: true
                  },
                  {
                    name: <div style={headerStyle}>Tahun</div>,
                    selector: (row) => <div style={cellStyle}>{row.tahun ?? '-'}</div>,
                    sortable: false,
                    center: true
                  },
                  {
                    name: <div style={headerStyle}>Kelas</div>,
                    selector: (row) => <div style={cellStyle}>{row.kelas ?? '-'}</div>,
                    sortable: false,
                    center: true,
                    style: { whiteSpace: 'nowrap !important' }
                  },
                  {
                    name: <div style={headerStyle}>Tanggal Daftar</div>,
                    selector: (row) => <div style={cellStyle}>{moment(row.tgl_daftar).format('DD/MM/YYYY')}</div>,
                    sortable: false,
                    center: true
                  },
                  {
                    name: <div style={headerStyle}>Action</div>,
                    cell: (x) => <Dropdown>
                      <Dropdown.Toggle
                        variant='secondary'
                        id='dropdown-basic'
                        className='button-action'
                      >
                        <FontAwesomeIcon icon={faEllipsisH} />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className='button-action-menu'>
                        <Dropdown.Item
                          className='dropdown-text'
                          as={Link}
                          to={{
                            pathname: `/approval/detail`,
                            state: { detail: x }
                          }}
                        >
                          <FontAwesomeIcon
                            className='fa-fw'
                            icon={faEye}
                          />{' '}
                          View
                        </Dropdown.Item>
                        <Dropdown.Item
                          className='dropdown-text'
                          onClick={() => approvalData(x)}
                        >
                          <FontAwesomeIcon
                            className='fa-fw'
                            icon={faCheckCircle}
                          />{' '}
                          Approve
                        </Dropdown.Item>
                        <Dropdown.Item
                          className='dropdown-text'
                          onClick={() => handleShowReject(x)}
                        >
                          <FontAwesomeIcon
                            className='fa-fw'
                            icon={faTimesCircle}
                          />{' '}
                          Reject
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>,
                    sortable: false,
                    center: true
                  },
                ]}
                data={listApproval}
                export={false}
                print={false}
                filter={false}
              >
                <DataTable
                  pagination={false}
                  responsive
                  onSelectedRowsChange={handleRowSelected}
                  selectableRows
                />
              </DataTableExtensions>
            </div>
          </div>
          <div className='footer-table d-flex justify-content-between align-items-center'>
            <div>
              {!_.isEmpty(listApproval) ? (
                <>
                  Menampilkan data {currentPage * perPage - perPage + 1} -{' '}
                  {listApproval.length == perPage
                    ? currentPage * perPage
                    : currentPage * perPage -
                    (perPage - listApproval.length)}{' '}
                  dari {dataCount} data
                </>
              ) : (
                <>Menampilkan data 0 - 0 dari 0 data</>
              )}
            </div>
            <div>
              <ReactPaginate
                pageRangeDisplayed={5}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                previousLabel='Sebelumnya'
                nextLabel='Selanjutnya'
                pageClassName='page-item'
                pageLinkClassName='page-link'
                previousClassName='page-item'
                previousLinkClassName='page-link'
                nextClassName='page-item'
                nextLinkClassName='page-link'
                breakLabel='...'
                breakClassName='page-item'
                breakLinkClassName='page-link'
                containerClassName='pagination'
                activeClassName='active'
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </Card.Body>
      </Card>

      <Modal
        className="modal-content-custom"
        show={showReject}
        onHide={handleCloseReject}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body style={{ padding: '40px' }}>
          <Form onSubmit={rejectData}>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="12">
                Alasan
              </Form.Label>
              <Col sm="12">
                {/* <Form.Control className="custom-form-control" type="text" name="lokasi" placeholder="Masukkan lokasi event" autoComplete="off" /> */}
                <Form.Control
                  className="custom-form-control"
                  as="textarea"
                  rows={5}
                  name="alasan"
                  placeholder="Masukkan alasan"
                  required
                />
              </Col>
            </Form.Group>

            <div className="text-center" style={{ marginTop: '50px' }}>
              <Button variant="secondary" onClick={handleCloseReject} style={{ width: '100px', marginRight: '10px' }}>
                Batal
              </Button>
              <Button type="submit" variant="outline-danger" style={{ width: '100px', fontWeight: 'bold' }}>Reject</Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Approval
