import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import L from 'leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faClock, faPlus, faSearchLocation } from '@fortawesome/free-solid-svg-icons'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import axios from 'axios';
import Swal from 'sweetalert2'
import * as AiIcons from 'react-icons/ai';
import * as BsIcons from 'react-icons/bs';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import ServiceApi from '../../api/MyApi';

const TambahInterest = () => {
    const history = useHistory();

    const [checkedMetode, setCheckedMetode] = useState(1);
    useEffect(() => {

    }, [])

    const submitData = async (e) => {
        e.preventDefault();

        const data = {
            'class': e.target.elements.kelas.value,
            type: checkedMetode
        }

        new ServiceApi().addKelas(data)
            .then(response => {
                Swal.fire({
                    title: 'Sukses!',
                    html: '<i>' + response.data.message + '</i>',
                    icon: 'success',
                    confirmButtonColor: '#0058a8',
                }).then(function () {
                    history.replace('/kelas')
                })
            }).catch(err => {
                Swal.fire({
                    title: 'Gagal!',
                    html: '<i>' + err.response.data.data.class ? err.response.data.data.class : '' + '</i>',
                    icon: 'error',
                    confirmButtonColor: '#0058a8',
                })
            });
    }
    const setCheckMetode = (e) => {
        setCheckedMetode(e)
    }

    return (
        <div className='main-animation'>
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div>
                    <Link className="content-link" to={{ pathname: `/kelas` }}><h3 className="content-title"><FontAwesomeIcon icon={faArrowLeft} size="sm" />&nbsp; Tambah Kelas</h3></Link>
                </div>
            </div>

            <Form onSubmit={submitData}>
                <Card className="card-main-content">
                    <Card.Body>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="12">
                                Nama Kelas
                            </Form.Label>
                            <Col sm="12">
                                <Form.Control className="custom-form-control" type="text" name="kelas" placeholder="Masukkan nama kelas" autoComplete="off" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="12">
                                Tipe Kelas
                            </Form.Label>
                            <Col md="12" lg="12" sm="12" xl="12" className='d-flex flex-row' style={{ gap: 10 }}>
                                <div
                                    className='input-radio-custom'
                                    onClick={() => setCheckMetode(1)}
                                >
                                    <Form.Check
                                        inline
                                        checked={checkedMetode == 1}
                                        label="Tipe 1"
                                        name="type_1"
                                        type="radio"
                                        onChange={() => setCheckMetode(1)}
                                        id={`inline-type_1`}
                                    />
                                </div>
                                <div
                                    className='input-radio-custom'
                                    onClick={() => setCheckMetode(2)}
                                >
                                    <Form.Check
                                        inline
                                        checked={checkedMetode == 2}
                                        label="Tipe 2"
                                        name="type_2"
                                        type="radio"
                                        onChange={() => setCheckMetode(2)}
                                        id={`inline-type_2`}
                                    />
                                </div>
                                <div
                                    className='input-radio-custom'
                                    onClick={() => setCheckMetode(3)}
                                >
                                    <Form.Check
                                        inline
                                        checked={checkedMetode == 3}
                                        label="Tipe 3"
                                        name="type_3"
                                        type="radio"
                                        onChange={() => setCheckMetode(3)}
                                        id={`inline-type_3`}
                                    />
                                </div>
                            </Col>
                            <Col sm="12">
                                <Button className="content-button-submit" variant="primary" type="submit">Tambah</Button>
                            </Col>
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Form>
        </div>
    );
};

export default TambahInterest;
