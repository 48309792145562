import React from "react";
import * as RiIcons from "react-icons/ri";
import {GoPrimitiveDot as Dot} from "react-icons/go";

export const SidebarData = [
    {
        title: "Dashboard",
        path: "/dashboard",
        icon: <img src={require('../../icons/home.svg').default} alt='mySvgImage' />,
    },
    {
        title: "Approval",
        path: "/approval",
        icon: <img src={require('../../icons/mouse-pointer.svg').default} alt='mySvgImage' />,
    },
    {
        title: "User",
        path: "#",
        icon: <img src={require('../../icons/users.svg').default} alt='mySvgImage' />,
        iconClosed: <RiIcons.RiArrowDownSLine />,
        iconOpened: <RiIcons.RiArrowUpSLine />,

        subNav: [
            {
                title: "User Aktif",
                path: "/user/aktif",
                icon: <Dot />,
                cName: "sub-nav",
            },
            {
                title: "User Tidak Aktif",
                path: "/user/tidak_aktif",
                icon: <Dot />,
                cName: "sub-nav",
            },
        ],
    },
    {
        title: "Postingan User",
        path: "/posts_user",
        icon: <img src={require('../../icons/file.svg').default} alt='mySvgImage' />,
    },
    {
        title: "Postingan Admin",
        path: "/posts_admin",
        icon: <img src={require('../../icons/feather.svg').default} alt='mySvgImage' />,
    },
    {
        title: "Events KSI",
        path: "/events",
        icon: <img src={require('../../icons/calendar.svg').default} alt='mySvgImage' />,
    },
    {
        title: "Interest",
        path: "/interest",
        icon: <img src={require('../../icons/heart.svg').default} alt='mySvgImage' />,
    },
    {
        title: "Kelas",
        path: "/kelas",
        icon: <img src={require('../../icons/copy.svg').default} alt='mySvgImage' />,
    },
    {
        title: "Tahun Belajar",
        path: "/tahun-belajar",
        icon: <img src={require('../../icons/education-1.svg').default} alt='mySvgImage' />,
    },
    {
        title: "Kategori",
        path: "/kategori",
        icon: <img src={require('../../icons/command.svg').default} alt='mySvgImage' />,
    },
];
