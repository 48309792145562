import React, { useState, useEffect, useCallback } from 'react';
import { Carousel } from 'react-responsive-carousel';
import L from 'leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCalendar, faClock, faCloudUploadAlt, faComment, faDownload, faEye, faMapMarkedAlt, faMapMarkerAlt, faPaperclip, faPaperPlane, faPlus, faSearchLocation, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import axios from 'axios';
import Swal from 'sweetalert2'
import * as AiIcons from 'react-icons/ai';
import * as BsIcons from 'react-icons/bs';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom'; import ServiceApi from '../../api/MyApi';
import { useDropzone } from 'react-dropzone';
import { Editor } from '@tinymce/tinymce-react';

const EditEvent = () => {
    const history = useHistory();
    const location = useLocation();
    const myparam = location.state;
    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');
    const [lokasi, setLocation] = useState('');
    const [content, setContent] = useState('');
    const [fileDefault, setFileDefault] = useState([]);
    const [dataFiles, setFiles] = useState([]);
    const [previewImages, setPreviewImages] = useState([]);
    const [attchDefault, setAttchDefault] = useState([]);
    const [dataFiles2, setFiles2] = useState([]);
    const [previewAttch, setPreviewAttch] = useState([]);
    const [valueTextEditor, setTextEditor] = useState("");

    // useEffect(() => {
    //     return () => file.forEach(file => URL.revokeObjectURL(file));
    // }, [])

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        new ServiceApi().detailEvents({ key: myparam.event }).then(x => {
            setTitle(x.data.data.title)
            setDate(x.data.data.date)
            setLocation(x.data.data.location)
            setContent(x.data.data.content)
            setFileDefault(x.data.data.image)
            setAttchDefault(x.data.data.attachment)
        }).catch((err) => {
        })
    }

    const onDrop = useCallback(acceptedFiles => {
        setFiles(acceptedFiles);
        setPreviewImages(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })))
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg', '.jpg']
        }
    });

    const { getRootProps: getRootAttachments, getInputProps: getInputAttachments, isDragActive: isDragActive2 } = useDropzone({
        onDrop: (acceptedFile) => {
            setFiles2(acceptedFile)
            setPreviewAttch(acceptedFile)
        },
        accept: {
            'application/pdf': ['.pdf'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/msword': ['.doc'],
        }
    });

    const deleteData = async (e) => {
        let formData = new FormData();

        formData.append('key', myparam.event);
        formData.append('judul', title);
        formData.append('tanggal', date);
        formData.append('lokasi', lokasi);
        fileDefault.forEach((file) => formData.append('deleted_image[]', file));
        formData.append('konten', content);

        new ServiceApi().updateEvents(formData)
            .then(response => {
                setFileDefault([])
                getData();
            }).catch(err => {
                Swal.fire({
                    title: 'Gagal!',
                    html: '<i>' + err.response.data.message + '</i>',
                    icon: 'error',
                    confirmButtonColor: '#0058a8',
                })
            });
    }

    const deleteFile = async (e) => {
        let formData = new FormData();

        const data = attchDefault.map(x => {
            return x.hash_name
        })

        formData.append('key', myparam.event);
        formData.append('judul', title);
        formData.append('tanggal', date);
        formData.append('lokasi', lokasi);
        data.forEach((file) => formData.append('deleted_attachment[]', file));
        formData.append('konten', content);

        new ServiceApi().updateEvents(formData)
            .then(response => {
                setAttchDefault([])
                getData();
            }).catch(err => {
                Swal.fire({
                    title: 'Gagal!',
                    html: '<i>' + err.response.data.message + '</i>',
                    icon: 'error',
                    confirmButtonColor: '#0058a8',
                })
            });
    }

    const handleEditorChange = (e) => {
        setTextEditor(e.target.getContent());
    }

    const submitData = async (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append('key', myparam.event);
        formData.append('judul', title);
        formData.append('tanggal', date);
        formData.append('lokasi', lokasi);

        if (!_.isEmpty(dataFiles)) {
            dataFiles.forEach((file) => formData.append('image[]', file));
        }

        if (!_.isEmpty(dataFiles2)) {
            dataFiles2.forEach((file) => formData.append('attachment[]', file));
        }

        formData.append('konten', content);

        new ServiceApi().updateEvents(formData)
            .then(response => {
                Swal.fire({
                    title: 'Sukses!',
                    html: '<i>' + response.data.message + '</i>',
                    icon: 'success',
                    confirmButtonColor: '#0058a8',
                }).then(function () {
                    history.push('/events')
                })
            }).catch(err => {
                Swal.fire({
                    title: 'Gagal!',
                    html: '<i>' + err.response.data.message + '</i>',
                    icon: 'error',
                    confirmButtonColor: '#0058a8',
                })
            });
    }

    if (!myparam) return <Redirect to="/events" />

    return (
        <div className='main-animation'>
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div>
                    <Link className="content-link" to={{ pathname: `/events` }}><h3 className="content-title"><FontAwesomeIcon icon={faArrowLeft} size="sm" />&nbsp; Update Event</h3></Link>
                </div>
            </div>

            <Form onSubmit={submitData}>
                <Card className="card-main-content">
                    <Card.Body>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="12">
                                Judul Event
                            </Form.Label>
                            <Col sm="12">
                                <Form.Control
                                    className="custom-form-control"
                                    type="text"
                                    name="judul"
                                    value={title}
                                    placeholder="Masukkan judul event"
                                    autoComplete="off"
                                    required
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="12">
                                Tanggal Event
                            </Form.Label>
                            <Col sm="12">
                                <Form.Control
                                    className="custom-form-control"
                                    type="date"
                                    name="tanggal"
                                    value={date}
                                    placeholder="Masukkan tanggal event"
                                    autoComplete="off"
                                    required
                                    onChange={(e) => setDate(e.target.value)}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="12">
                                Lokasi Event
                            </Form.Label>
                            <Col sm="12">
                                <Form.Control
                                    className="custom-form-control"
                                    type="text"
                                    name="lokasi"
                                    value={lokasi}
                                    placeholder="Masukkan lokasi event"
                                    autoComplete="off"
                                    required
                                    onChange={(e) => setLocation(e.target.value)}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="12">
                                Poster Event
                            </Form.Label>
                            <Col lg="12">
                                {
                                    _.isEmpty(fileDefault) && _.isEmpty(dataFiles) ?
                                        <>
                                            <div className='drop-files-upload' {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                {
                                                    isDragActive ?
                                                        <div className='d-flex flex-column justify-content-center align-items-center' style={{ paddingTop: 40, paddingBottom: 40 }}>
                                                            <FontAwesomeIcon className="cloud-icon" icon={faCloudUploadAlt} size="2x" />
                                                            <p>Taruh File disini...</p>
                                                        </div> :
                                                        <div className='d-flex flex-column justify-content-center align-items-center' style={{ paddingTop: 40, paddingBottom: 40 }}>
                                                            <FontAwesomeIcon className="cloud-icon" icon={faCloudUploadAlt} size="2x" />
                                                            <p>Klik atau taruh untuk memilih file</p>
                                                            <p style={{ fontSize: 13 }}><i>JPG, JPEG, PNG</i></p>
                                                        </div>
                                                }
                                            </div>
                                        </>
                                        :
                                        <div className="row">
                                            {
                                                _.isEmpty(previewImages) ?
                                                    <>
                                                        {fileDefault.map(item => (
                                                            <div className="col-3">
                                                                <img
                                                                    width="250"
                                                                    height="400"
                                                                    src={process.env.REACT_APP_BASE_URL_STORAGE_IMAGE_EVENT + item}
                                                                    style={{
                                                                        borderRadius: '20px',
                                                                        margin: '10px 20px 0 0',
                                                                    }}
                                                                // onLoad={() => { URL.revokeObjectURL(file.preview) }}
                                                                />
                                                            </div>
                                                        ))}
                                                        <div className="col-12">
                                                            <Button
                                                                className="content-button text-center mt-4"
                                                                // variant="danger"
                                                                onClick={deleteData}
                                                                style={{
                                                                    height: '40px',
                                                                    width: '150px'
                                                                }}
                                                            >
                                                                Upload
                                                            </Button>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        {previewImages.map(file => (
                                                            <div key={file.name} className="col-3">
                                                                <img
                                                                    // className="m-4"
                                                                    width="250"
                                                                    height="400"
                                                                    src={file.preview}
                                                                    style={{
                                                                        borderRadius: '20px',
                                                                        margin: '10px 20px 0 0',
                                                                    }}
                                                                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                                                                />
                                                            </div>
                                                        ))}
                                                        <div className="col-12">
                                                            <Button
                                                                className="text-center mt-4"
                                                                variant="danger"
                                                                onClick={() => setFiles([])}
                                                                style={{
                                                                    height: '40px',
                                                                    width: '150px'
                                                                }}
                                                            >
                                                                Hapus
                                                            </Button>
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                }
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="12">
                                Attachments
                            </Form.Label>
                            <Col lg="12">
                                {
                                    _.isEmpty(attchDefault) && _.isEmpty(dataFiles2) ?
                                        <div className='drop-files-upload' {...getRootAttachments()}>
                                            <input {...getInputAttachments()} />
                                            {
                                                isDragActive2 ?
                                                    <div className='d-flex flex-column justify-content-center align-items-center' style={{ paddingTop: 40, paddingBottom: 40 }}>
                                                        <FontAwesomeIcon className="cloud-icon" icon={faCloudUploadAlt} size="2x" />
                                                        <p>Taruh File disini...</p>
                                                    </div> :
                                                    <div className='d-flex flex-column justify-content-center align-items-center' style={{ paddingTop: 40, paddingBottom: 40 }}>
                                                        <FontAwesomeIcon className="cloud-icon" icon={faCloudUploadAlt} size="2x" />
                                                        <p>Klik atau taruh untuk memilih file</p>
                                                        <p style={{ fontSize: 13 }}><i>DOC, DOCX, PDF</i></p>
                                                    </div>
                                            }
                                        </div>
                                        : _.isEmpty(previewAttch) ?
                                            <>
                                                <div className="row d-flex justify-content-start">
                                                    {
                                                        attchDefault.map((x, key) => {
                                                            return (
                                                                <div key={key} style={{ cursor: 'pointer', color: '#39f' }} className="d-flex flex-row" onClick={() => window.open(process.env.REACT_APP_BASE_URL_STORAGE_ATTACHMENTS_EVENT + x?.hash_name, '_blank')}>
                                                                    <p>{key + 1}.&nbsp;</p>
                                                                    <p>{x.original_name}</p>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <Button
                                                    className="content-button text-center mt-2"
                                                    variant="danger"
                                                    onClick={deleteFile}
                                                    style={{
                                                        height: '40px',
                                                        width: '150px'
                                                    }}
                                                >
                                                    Upload
                                                </Button>
                                            </>
                                            :
                                            <>
                                                <div className="row d-flex justify-content-start">
                                                    {
                                                        dataFiles2.map((x, key) => {
                                                            return (
                                                                <div key={key} className="d-flex flex-row">
                                                                    <p>{key + 1}.&nbsp;</p>
                                                                    <p>{x.name}</p>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <Button
                                                    className="text-center mt-2"
                                                    variant="danger"
                                                    onClick={() => setFiles2([])}
                                                    style={{
                                                        height: '40px',
                                                        width: '150px'
                                                    }}
                                                >
                                                    Batal
                                                </Button>
                                            </>
                                }
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="12">
                                Deskripsi Singkat Event
                            </Form.Label>
                            <Col sm="12">
                                <Editor
                                    tinymceScriptSrc="/tinymce/tinymce.min.js"
                                    apiKey="d1h6va9igad06j5p5bckn9zvl3ikezdefxawmfljczrcfbq2"
                                    init={{
                                        width: '100%',
                                        height: '50vh',
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image',
                                            'charmap print preview anchor help',
                                            'searchreplace visualblocks code emoticons',
                                            'insertdatetime media table paste wordcount image'
                                        ],
                                        toolbar:
                                            'undo redo | styleselect | bold italic underline strikethrough subscript superscript | \
                                            alignleft aligncenter alignright | \
                                            bullist numlist outdent indent link image | emoticons help',
                                        content_style:
                                            "body { font-family: HANDotum; }",
                                        font_formats:
                                            "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; HANDotum=hANDotum; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
                                    }}
                                    onChange={handleEditorChange}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Col sm="12">
                                <Button className="content-button-submit" variant="primary" type="submit">Publikasi</Button>
                            </Col>
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Form>
        </div>
    );
};

export default EditEvent;
