import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import L from 'leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCalendar, faClock, faComment, faDownload, faEllipsisH, faEye, faMapMarkedAlt, faMapMarkerAlt, faPaperclip, faPaperPlane, faPlus, faSearchLocation, faThumbsUp, faTrash } from '@fortawesome/free-solid-svg-icons'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { Button, Card, Col, Container, Dropdown, Form, Row } from 'react-bootstrap';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import axios from 'axios';
import Swal from 'sweetalert2'
import * as AiIcons from 'react-icons/ai';
import * as BsIcons from 'react-icons/bs';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom'; import ServiceApi from '../../../api/MyApi';
;

const DetailPostingan = () => {
    const location = useLocation();
    const myparam = location.state;
    const history = useHistory();
    const [detail, setDetail] = useState([]);
    const [userProfile, setUserProfile] = useState('');

    useEffect(() => {
        new ServiceApi().getDetailPostinganUser({ key: myparam.posts_user }).then(x => {
            setDetail(x.data.data);
            setUserProfile(x.data.data.user)
        }).catch((err) => {
        })
    }, [])

    if (!myparam) return <Redirect to="/posts_user" />

    return (
        <div className='main-animation'>
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div>
                    <Link className="content-link" to={{ pathname: `/posts_user` }}><h3 className="content-title"><FontAwesomeIcon icon={faArrowLeft} size="sm" />&nbsp; Detail Postingan</h3></Link>
                </div>
            </div>

            <Form>
                <Card className="card-main-content">
                    {/* <Card.Header
                        style={{ backgroundColor: '#fff', border: 'none' }}
                    >
                        <div style={{ width: '100%', padding: '20px' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%'
                                }}
                            >
                                <h2 style={{ letterSpacing: '0.3px', color: '#333' }}>
                                    {detail.title}
                                </h2>
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginTop: '20px'
                                }}
                            >
                                <p style={{ fontSize: '20px', color: '#828282', letterSpacing: '0.3px' }}>
                                    <FontAwesomeIcon icon={faMapMarkerAlt} />&nbsp; {detail.location}
                                </p>
                                <p style={{ fontSize: '20px', color: '#828282', letterSpacing: '0.3px' }}>
                                    <FontAwesomeIcon icon={faCalendar} />&nbsp; {detail.created_at ? moment(detail.created_at).fromNow() : '- Jam Lalu'}
                                </p>
                            </div>
                        </div>
                    </Card.Header> */}
                    <Card.Body>
                        <div className="head-table mb-4">
                            <div id="size-table" className="size-table">
                                {
                                    userProfile.avatar == null ?
                                        <div style={{ width: '60px', height: '60px', backgroundColor: '#828282', borderRadius: '50%', marginRight: '20px' }}></div>
                                        :
                                        <img
                                            src={process.env.REACT_APP_BASE_URL_STORAGE_AVATAR_POST + userProfile.avatar}
                                            width="60"
                                            height="60"
                                            style={{
                                                borderRadius: '50%',
                                                marginRight: '20px'
                                            }}
                                        />
                                }
                                <p className="mb-0" style={{ color: '#2F90ED', marginRight: '20px' }}>{userProfile.name}</p>
                                <p style={{ color: '#828282', letterSpacing: '0.3px', margin: '0' }}>
                                    {detail.created_at ? moment(detail.created_at).fromNow() : '- Jam Lalu'}
                                </p>
                            </div>
                        </div>
                        <h2 style={{ letterSpacing: '0.3px', color: '#333' }}>
                            {detail.title}
                        </h2>
                        <div className="mt-4 mb-4" style={{ color: '#939393' }} >
                            <div className="app-detail-post"
                                dangerouslySetInnerHTML={{
                                    __html: detail.content
                                }}
                            />
                        </div>
                        {
                            _.isEmpty(detail.image) ? (<></>) : (
                                <Carousel
                                    showThumbs={false}
                                    style={{
                                        width: '500px',
                                        display: 'block',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: '#000 !important',
                                    }}

                                >
                                    {
                                        detail.image.map((item, index) => {
                                            return (
                                                <div key={index}
                                                >
                                                    <img
                                                        // className="d-block w-100"
                                                        src={process.env.REACT_APP_BASE_URL_STORAGE_IMAGE_POST + item}
                                                        alt={"slide-" + index}
                                                        style={{ width: '100%', height: '400px', borderRadius: '15px', margin: '40px 0px 40px 0px' }}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </Carousel>
                            )
                        }

                        {
                            _.isEmpty(detail.attachment) ? <></> :

                                <div id="attachments-detail-post" className='attachment-detail-post'>
                                    <p className='title'>
                                        Attachments
                                    </p>
                                    {detail.attachment.map((item, index) => {
                                        return (
                                            <div
                                                className="body-file"
                                                key={index}>
                                                <div
                                                    id='file'
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'start',
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => window.open(process.env.REACT_APP_BASE_URL_STORAGE_ATTACHMENTS_POST + item?.hash_name, '_blank')}
                                                >
                                                    <FontAwesomeIcon icon={faPaperclip} size="1x" color='#828282' />
                                                    <p className='title-file'>
                                                        {item?.original_name}
                                                    </p>
                                                </div>
                                                <div
                                                    id="unduh-file"
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'start',
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => window.open(process.env.REACT_APP_BASE_URL_STORAGE_ATTACHMENTS_POST + item?.hash_name, '_blank')}
                                                >
                                                    <p style={{
                                                        fontSize: 12,
                                                        margin: '0px 10px 0px 0px',
                                                        color: '#828282'
                                                    }}>{item?.file_size ? item?.file_size + ' KB' : '0 KB'}</p>
                                                    <FontAwesomeIcon icon={faDownload} size="xs" color='#2F80ED' />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                        }
                        <div className="mt-3">
                            <p>
                                <FontAwesomeIcon icon={faThumbsUp} color="#828282" /> {detail.jumlah_like} &nbsp; &nbsp;
                                <FontAwesomeIcon icon={faComment} color="#828282" /> {detail.jumlah_komen} &nbsp; &nbsp;
                                <FontAwesomeIcon icon={faPaperPlane} color="#828282" /> {detail.share} &nbsp; &nbsp;
                                <FontAwesomeIcon icon={faEye} color="#828282" /> {detail.jumlah_like} &nbsp; &nbsp;
                            </p>
                        </div>
                    </Card.Body>
                </Card>
            </Form>
        </div>
    );
};

export default DetailPostingan;
