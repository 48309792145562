import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const SidebarLink = styled(Link)`
  display: flex;
  color: #fff;
  margin: 5px 15px 5px 15px;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;
  &:hover {
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
    background: rgba(0, 81, 154, 1);
    border-left: 4px solid #fff;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
  font-size: 14px;
`;

const DropdownLink = styled(Link)`
  background: #0058a8;
  border-radius: 5px;
  height: 60px;
  padding-left: 3rem;
  margin: 5px 15px 5px 15px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  &:hover {
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
    background: rgba(0, 81, 154, 1);
    cursor: pointer;
  }
`;

const SubMenu = ({ item, parentCallback }) => {
  const [subnav, setSubnav] = useState(false);
  const [myPath, setMyPath] = useState('my_path', []);
  const location = useLocation();
  const { height, width } = useWindowDimensions();

  const showSubnav = () => setSubnav(!subnav);

  const menuClick = (v) => {
    if(v){
      setSubnav(!subnav)
    } else {
      if (width < 600) {
        parentCallback(v);
      }
    }
  }

  useEffect(() => {
    setMyPath(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <SidebarLink to={item.path} style={myPath.includes(item.path) ? { backgroundColor: '#AF8037', borderLeft: '4px solid #fff' } : { backgroundColor: '#C9A66F' }} onClick={() => menuClick(item.subNav && showSubnav)}>
        <div>
          {item.icon}
          <SidebarLabel>{item.title}</SidebarLabel>
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
              ? item.iconClosed
              : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.map((item, index) => {
          return (
            <DropdownLink to={item.path} key={index} style={myPath.includes(item.path) ? { backgroundColor: '#AF8037', borderLeft: '4px solid #fff' } : { backgroundColor: '#C9A66F' }} onClick={() => menuClick(true)}>
              {item.icon}
              <SidebarLabel>{item.title}</SidebarLabel>
            </DropdownLink>
          );
        })}
    </>
  );
};

export default SubMenu;