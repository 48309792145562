import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faMapMarkerAlt, faCalendar, faClock } from '@fortawesome/free-solid-svg-icons'
import { Card, Col, Dropdown, Row, Modal, Button, Form, Spinner, Badge } from 'react-bootstrap';
import _ from 'lodash';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import * as AiIcons from 'react-icons/ai';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend, registerables
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Select from 'react-select';
import * as FaIcons from "react-icons/fa";
import ReactPaginate from "react-paginate";
import ServiceApi from '../api/MyApi';
import LogoMousePointer from "../img/mouse-pointer.png";
import LogoUser from "../img/user.png";
import LogoCalendar from "../img/calendar.png";
import LogoFile from "../img/file.png";
import Logo from "../img/logo.png";
import { IconContext } from 'react-icons';
import { defineRank, longText, longTextV2 } from '../helper/Helper';
import { Link } from 'react-router-dom';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  ...registerables
);

export const options = {
  responsive: true,
  scales: {
    y: {
      suggestedMin: 0,
      suggestedMax: 50
    }
  },
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: true,
      text: ['Jumlah', '2022'],
      position: 'bottom',
    },
  },
};

const labels = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];

function randomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const data = {
  labels,
  datasets: [
    {
      label: 'Jumlah Kegiatan',
      data: labels.map(() => randomNumber(0, 0)),
      borderColor: 'rgba(255, 143, 42, 1)',
      fill: true,
      backgroundColor: 'rgba(255, 221, 40, 0.5)',
    },
  ],
};

const currentYear = (new Date()).getFullYear();
const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));

const DashboardView = () => {
  const [listYear, setListYear] = useState([]);
  const [listFilterTahun, setListFilterTahun] = useState([
    { value: '2022', label: '2022' }
  ]);
  const [filterTahun, setFilterTahun] = useState(null);
  const [modalDetail, setModalDetail] = useState(false);
  const [search, setSearch] = useState("");
  const [titleDetail, setTitleDetail] = useState("");
  const [perPage, setPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [jenisJP, setJenisJP] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const [dataCard, setDataCard] = useState({
    totalNeedApproval: 0,
    totalUser: 0,
    totalPostingan: 0,
    topPost: [],
    topUsers: [],
    pegawaiTidakJPJumlah: 0,
    pegawaiMemenuhiJP: 0,
    pegawaiSebagianJP: 0,
    pegawaiTidakJP: 0,
    dataChart: {
      labels: ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'],
      datasets: [
        {
          label: 'Jumlah Kegiatan',
          data: ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'].map(() => randomNumber(0, 0)),
          borderColor: 'rgba(255, 143, 42, 1)',
          fill: true,
          backgroundColor: 'rgba(255, 221, 40, 0.5)',
        },
      ],
    }
  });
  const [tahunFilter, setTahunFilter] = useState(currentYear);
  const [modalShow, setModalShow] = useState(null);

  useEffect(() => {
    const currentYear = (new Date()).getFullYear();
    const rangeYear = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
    var data_year = rangeYear(currentYear, currentYear - 15, -1).map((row, i) => {
      return (
        { value: row, label: row }
      )
    })
    setListYear(data_year)
  }, []);

  useEffect(() => {
    async function fetchGetData() {
      await new ServiceApi().getDashboardData().then(x => {
        setDataCard({
          totalNeedApproval: x.data.need_approval,
          totalUser: x.data.total_user,
          totalPostingan: x.data.total_post,
          topPost: x.data.top_post,
          topUsers: x.data.top_user,
          dataChart: {
            labels: x.data?.jumlah_post?.map((x) => x.bulan),
            datasets: [
              {
                label: 'Jumlah Kegiatan',
                data: x.data?.jumlah_post?.map((x) => x.jumlah_post),
                borderColor: 'rgba(255, 143, 42, 1)',
                fill: true,
                backgroundColor: 'rgba(255, 221, 40, 0.5)',
                borderWidth: 2,
                borderRadius: 5,
                borderSkipped: false,
              },
            ],
          }
        })
      });
    }
    setListFilterTahun(range(currentYear, currentYear - 5, -1).map((row, i) => {
      return (
        { value: row, label: row }
      )
    }));
    fetchGetData();
  }, []);

  const fetchGeData = async (data) => {
    var data = {
      tahun: data.tahun,
    }
    await new ServiceApi().getDashboardJumlahPostingan(data).then(x => {
      setDataCard({
        totalNeedApproval: dataCard.totalNeedApproval,
        totalUser: dataCard.totalUser,
        totalPostingan: dataCard.totalPostingan,
        topPost: dataCard.topPost,
        topUsers: dataCard.topUsers,
        dataChart: {
          labels: x.data?.jumlah_post?.map((x) => x.bulan),
          datasets: [
            {
              label: 'Jumlah Kegiatan',
              data: x.data?.jumlah_post?.map((x) => x.jumlah_post),
              borderColor: 'rgba(255, 143, 42, 1)',
              fill: true,
              backgroundColor: 'rgba(255, 221, 40, 0.5)',
              borderWidth: 2,
              borderRadius: 5,
              borderSkipped: false,
            },
          ],
        }
      })
    });
  }

  const handleModal = (e) => {
    localStorage.setItem("welcome_modal", "0")
    setModalShow(e);
  }

  const setPenem = (e) => {
    setTahunFilter(_.capitalize(e.label));
    fetchGeData({ tahun: e.value });
  }

  return (
    <div className='main-animation'>
      <div className="d-flex flex-row flex-wrap justify-content-between align-items-center">
        <div>
          <br />
        </div>
      </div>

      <Row>
        <Col md={12} lg={8}>
          <Row>
            <Col>
              <Card className="card-main-content">
                <Card.Body className='information-dashboard'>
                  <Row>
                    <Col xs={12} md={4} lg={3} className="green-background-icon">
                      <div className='background-icon d-flex justify-content-center'>
                        <img src={LogoMousePointer} alt="logoUser" />
                      </div>
                    </Col>
                    <Col xs={12} md={8} lg={9} className='d-flex flex-column align-items-start justify-content-center'>
                      <div className='title-side'>
                        {dataCard.totalNeedApproval}
                      </div>
                      <div className='subtitle-side'>
                        Need Approval
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="card-main-content">
                <Card.Body className='information-dashboard'>
                  <Row>
                    <Col xs={12} md={4} lg={3} className="orange-background-icon">
                      <div className='background-icon d-flex justify-content-center'>
                        <img src={LogoUser} alt="logoCalendar" />
                      </div>
                    </Col>
                    <Col xs={12} md={8} lg={9} className='d-flex flex-column align-items-start justify-content-center'>
                      <div className='title-side'>
                        {dataCard.totalUser}
                      </div>
                      <div className='subtitle-side'>
                        Total User
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={12} style={{ marginTop: 16 }}>
              <Card className="card-main-content">
                <Card.Body>
                  <div className='d-flex flex-row justify-content-between'>
                    <div>
                      <h4 className="card-main-content-title">Jumlah Postingan</h4>
                      <p className="card-main-content-subtitle">Data pada tahun {tahunFilter}</p>
                    </div>
                    <div className='content-dropdown'>
                      <Dropdown>
                        <Dropdown.Toggle className='my-dropdown' id="dropdown-basic">
                          {tahunFilter} &nbsp;
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{ marginTop: 5, width: 300, overflowY: 'auto', maxHeight: 250 }}>
                          {
                            !_.isEmpty(listFilterTahun) ?
                              <>
                                {
                                  listFilterTahun.map((x, key) => {
                                    return (
                                      <Dropdown.Item key={key} onClick={() => setPenem(x)}>{_.upperCase(x.label)}</Dropdown.Item>
                                    )
                                  })
                                }
                              </>
                              :
                              <></>
                          }
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <Bar options={options} data={dataCard.dataChart ?? data} style={{ maxHeight: 400 }} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col md={12} lg={4}>
          <Row>
            <Col lg={12}>
              <Card className="card-main-content">
                <Card.Body className='information-dashboard'>
                  <Row>
                    <Col xs={12} md={4} lg={3} className="blue-background-icon">
                      <div className='background-icon d-flex justify-content-center'>
                        <img src={LogoFile} alt="logoCalendar" />
                      </div>
                    </Col>
                    <Col xs={12} md={8} lg={9} className='d-flex flex-column align-items-start justify-content-center'>
                      <div className='title-side'>
                        {dataCard.totalPostingan}
                      </div>
                      <div className='subtitle-side'>
                        Total Postingan
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={12} style={{ marginTop: 20 }}>
              <Card className="card-main-content">
                <Card.Body>
                  <h5>Top Users</h5>
                  <div className='top-users'>
                    {
                      _.isEmpty(dataCard?.topUsers) ?
                        <div>
                          <p className="latest-comments-text">
                            Tidak Ada Top User!<br />Yuk Coba Refresh Halaman Ini!
                          </p>
                        </div>
                        :
                        dataCard?.topUsers?.map((item, index) => {
                          let rankIndex = index + 1;
                          return (
                            <div key={index} style={{ marginTop: index === 0 ? 0 : 20 }}>
                              <Row>
                                <Col xs={1} md={1} lg={1} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                                  {defineRank(rankIndex)}
                                </Col>
                                <Col xs={2} md={2} lg={2} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                                  {
                                    item.avatar ?
                                      <img
                                        alt="avatar"
                                        src={process.env.REACT_APP_BASE_URL_STORAGE_AVATAR_POST + item.avatar}
                                        width="44px"
                                        height="44px"
                                        style={{ borderRadius: '50%', marginLeft: '10px' }}
                                      />
                                      :
                                      <img
                                        alt="avatar"
                                        src="https://cdn-icons-png.flaticon.com/512/1077/1077114.png?w=826&t=st=1667739571~exp=1667740171~hmac=1928188e6600631d49a41b235d4a046b348e65ff07071f330f3fde71c4463f00"
                                        width="44px"
                                        height="44px"
                                        style={{ borderRadius: '50%', marginLeft: '10px' }}
                                      />
                                  }
                                </Col>
                                <Col xs={9} md={9} lg={9} style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
                                  <div>
                                    <Link to={{ pathname: `/user/aktif/detail`, state: { detail: item } }} style={{ color: '#2F80ED', textDecoration: 'none' }}>
                                      {item.name}
                                    </Link>
                                  </div>
                                  <div>
                                    <p style={{ fontSize: '12px', color: '#828282' }}>{item.jumlah_post}</p>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          )
                        })
                    }
                  </div>

                </Card.Body>
              </Card>
            </Col>
            <Col lg={12} style={{ marginTop: 20 }}>
              <Card className="card-main-content">
                <Card.Body className='information-dashboard'>
                  <h5>Top Posting</h5>
                  {
                    _.isEmpty(dataCard?.topPost) ?
                      <div>
                        <p css={{ pt: "$10" }}>Tidak ada postingan.</p>
                      </div>
                      :
                      dataCard?.topPost?.map((v, i) => {
                        let rankIndex = i + 1;
                        return (
                          <Link key={i}  to={{ pathname: `/posts_user/detail`, state: { posts_user: v.encrypted_id } }} style={{
                            textDecoration: 'none',
                          }}>
                            <p className="list-posting">
                              <Badge bg="warning" style={{
                                borderRadius: '50%',
                                background: rankIndex > 3 ? '#fff' : '#BD8A3A',
                                color: rankIndex > 3 ? '#4F4F4F' : ''
                              }}>
                                {rankIndex}
                              </Badge>
                              <span style={{ marginLeft: 10, color: '#4F4F4F' }}>
                                {longTextV2(v.title, 40, 80)}
                              </span>
                            </p>
                          </Link>
                        )
                      })
                  }
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <MyVerticallyCenteredModal
        show={modalShow == "1"}
        onHide={() => handleModal("0")}
      />
    </div>
  );
};

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      centered
      className="modal-filter"
    >
      <Modal.Body className='d-flex flex-column justify-content-center align-items-center'>
        <div>
          <img src={Logo} className="img-fluid" alt="logoOmbudsman" width={120} style={{ marginTop: 50, marginBottom: 50 }} />
        </div>
        <div className='text-center'>
          <h4><b>Aplikasi Pengembangan Kompetensi<br />Ombudsman Republik Indonesia</b></h4>
        </div>
        <br />
        <div className='text-center'>
          <p>
            Aplikasi ini sebagai Sistem Informasi untuk Pengelolaan Data<br />Pengembangan Kompetensi Pegawai di Lingkungan<br />Ombudsman Republik Indonesia
          </p>
        </div>
        <Button onClick={props.onHide} style={{ marginTop: 40, marginBottom: 50, backgroundColor: 'rgba(0, 88, 168, 1)', color: 'white' }}>Mengerti</Button>
      </Modal.Body>
    </Modal>
  );
}

export default DashboardView;
