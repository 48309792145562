import React, { useContext, useState } from 'react';
//import { useHistory } from 'react-router-dom';
import { Button, Card, Col, Form, Image, InputGroup, Row } from 'react-bootstrap';
import PresenceContext from '../context/OmbudsmanContext';
import axios from 'axios';
import Swal from 'sweetalert2';
import Logo from '../img/logo.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import logoImage from '../img/imageloginone.png';
import { useEffect } from 'react';
import { firebaseCloudMessaging } from '../utils/webPush';

const Login = (props) => {
    const { setRemember } = useContext(PresenceContext);
    const [rmb, setRmb] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [tokenFCM, setTokenFCM] = useState(null);

    const handleChange = e => {
        setPasswordShown(passwordShown ? false : true);
    }

    const sampleLogin = async (e) => {
        e.preventDefault();
        // localStorage.setItem("welcome_modal", "1")
        axios({
            method: 'post',
            url: process.env.REACT_APP_BASE_API + '/login',
            headers: {},
            data: {
                username: e.target.email.value,
                password: e.target.password.value,
                device_token: tokenFCM
            }
        }).then(response => {
            setRemember({ remember: rmb, ...response.data });
            window.location.reload();
        }).catch(err => {
            Swal.fire({
                title: 'Gagal!',
                text: err.response.data.message,
                icon: 'error'
            })
        });
    }
    
    useEffect(async () => {
        firebaseCloudMessaging.tokenInlocalforage()
            .then((currentToken) => {
                if (currentToken) {
                    setTokenFCM(currentToken);
                }
            })
            .catch((err) => {
                console.log("An error occurred while retrieving token. ", err);
            });
    }, []);

    return (
        <div className="login">
            <Card className="login-card container">
                <Card.Body className="p-0">
                    <div className="d-flex flex-column justify-content-center align-items-center align-content-center" style={{ height: '100%' }}>
                        <div className="login-logo">
                            <Image
                                className=""
                                src={logoImage}
                            />
                        </div>
                        <div className="login-title">
                            <p>Login Admin</p>
                        </div>
                        <div style={{ width: '100%' }}>
                            <Form onSubmit={sampleLogin}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label className="text-label">Username</Form.Label>
                                    <Form.Control className="login-form-control" placeholder="Masukkan username Anda" type="email" name="email" autoComplete="off" required />
                                </Form.Group>

                                <br />

                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label className="text-label">Password</Form.Label>
                                    <Form.Control className="login-form-control" placeholder="Masukkan password Anda" type="password" name="password" autoComplete="off" required />
                                </Form.Group>
                                <Button variant="primary" type="submit" className="button-login" style={{ width: '100%', marginTop: '50px' }}>
                                    Sign In
                                </Button>
                            </Form>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};

export default Login;
